import { Avatar, Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { TopicWithTagColor } from '@/utils/constants/types';
import BlankCard from '@/components/shared/BlankCard';
import { TopicTagChip } from '../TopicTagChip';
import { MentionsSkeleton } from './TopTopicMobileCard';
import { useSession } from '@/context/session';
import { useLens } from '@/context/lens';
import { useBackend } from '@/context/backend';
import { useEffect, useState } from 'react';
import { ProfileFragment } from '@lens-protocol/client';
import ProfileHover from '@/components/shared/ProfileHover';
import { getAvatarUrl } from '@/utils';
import Link from 'next/link';
type CommDashboardFilterViewProps = {
  isLoading: boolean;
  topicData?: TopicWithTagColor;
};
export const TopTopicDesktopCard = ({
  isLoading,
  topicData
}: CommDashboardFilterViewProps) => {
  const theme = useTheme();
  const {
    fetchTopEngagers,
    fetchTopicMentions
  } = useBackend();
  const {
    getProfilesById
  } = useLens();
  const {
    user
  } = useSession();
  const [isLoadingMentions, setIsLoadingMentions] = useState(false);
  const [topEngagers, setTopEngagers] = useState<ProfileFragment[]>([]);
  const [fromNetwork, setFromNetwork] = useState<ProfileFragment[]>([]);
  const [totalMentions, setTotalMentions] = useState<number>();
  useEffect(() => {
    if (topicData?.id) {
      getTopicMentions(topicData.id);
    }
    return cleanState;
  }, [topicData, user.profileId]);
  const getTopicMentions = async (topicId: string) => {
    setIsLoadingMentions(true);
    try {
      const mentions = await fetchTopicMentions(topicId);
      setTotalMentions(Number(mentions.totalMentions));
      const engagersData = await fetchTopEngagers(topicId, user.profileId);
      const topEngagersIds = engagersData?.topEngagers?.map(engager => engager.profileId);
      const followedEngagersIds = engagersData?.followedEngagers?.map(engager => engager.profileId);
      const profilesToFetch = Array.from(new Set([...(topEngagersIds || []), ...(followedEngagersIds || [])]));
      if (profilesToFetch.length) {
        const allProfilesData = await getProfilesById(profilesToFetch);
        const topEngagersData = allProfilesData.items.filter(profile => topEngagersIds?.includes(profile.id));
        const followedEngagersData = allProfilesData.items.filter(profile => followedEngagersIds?.includes(profile.id));
        setTopEngagers(topEngagersData);
        setFromNetwork(followedEngagersData);
      }
    } catch (error) {
      console.log('Error while fetching topics data', error);
    } finally {
      setIsLoadingMentions(false);
    }
  };
  const cleanState = () => {
    setTotalMentions(undefined);
    setTopEngagers([]);
    setFromNetwork([]);
  };
  if (!isLoading && !topicData) {
    return <Box display="flex" flexDirection="column" width={'50%'}>
        <Typography variant="h4">💡 Insight of the day</Typography>
        <Box display="flex" flexDirection="column" justifyContent="start" sx={{
        py: 2,
        px: {
          xs: 2,
          md: 3
        },
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}>
          <BlankCard>
            <Box sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
              <Typography variant="h6" textAlign={'center'} color={'text.secondary'}>
                No data
              </Typography>
            </Box>
          </BlankCard>
        </Box>
      </Box>;
  }
  return <Box display="flex" flexDirection="column" padding={2} gap={2} data-sentry-element="Box" data-sentry-component="TopTopicDesktopCard" data-sentry-source-file="TopTopicDesktopCard.tsx">
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={1} data-sentry-element="Box" data-sentry-source-file="TopTopicDesktopCard.tsx">
        <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="TopTopicDesktopCard.tsx">💡 Insight of the day</Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="start" bgcolor={theme.palette.grey[100]} sx={{
      py: 2,
      px: {
        xs: 2,
        md: 3
      },
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden'
    }} data-sentry-element="Box" data-sentry-source-file="TopTopicDesktopCard.tsx">
        <Box display={'flex'} flexDirection={'column'} gap={2} sx={{
        minHeight: 'min-content'
      }} data-sentry-element="Box" data-sentry-source-file="TopTopicDesktopCard.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="TopTopicDesktopCard.tsx">
            <Box display="flex" flexDirection="row" justifyContent={'space-between'} bgcolor={theme.palette.grey[100]} data-sentry-element="Box" data-sentry-source-file="TopTopicDesktopCard.tsx">
              <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="TopTopicDesktopCard.tsx">{topicData?.topic}</Typography>

              <Box sx={{
              ml: '-10%'
            }} data-sentry-element="Box" data-sentry-source-file="TopTopicDesktopCard.tsx">
                {isLoadingMentions ? <MentionsSkeleton /> : <Stack direction={'row'} alignItems={'center'} gap={'1rem'}>
                    <Box display="flex" flexDirection="column" gap={0.6} justifyContent={'space-between'}>
                      {topEngagers.length ? <Stack direction="row" spacing={1} alignItems="center">
                          <Stack direction="row" spacing={-0.5}>
                            {topEngagers.map(user => <ProfileHover user={user} key={`engager-${user.id}`}>
                                <Link href={`/profile/${user.id}`}>
                                  <Avatar src={getAvatarUrl(user)} alt={user.handle?.localName} sx={{
                            width: 28,
                            height: 28,
                            border: `1px solid ${theme.palette.background.paper}`,
                            '&:hover': {
                              cursor: 'pointer',
                              filter: 'brightness(0.5)',
                              transition: '0.2s'
                            }
                          }} />
                                </Link>
                              </ProfileHover>)}
                          </Stack>
                          <Typography variant="h6">
                            {totalMentions?.toLocaleString()} mentions 🔥
                          </Typography>
                        </Stack> : null}

                      {fromNetwork.length ? <Stack direction="row" spacing={1} alignItems="center">
                          <Stack direction="row" spacing={-0.5}>
                            {fromNetwork.map(user => <Tooltip title={user.handle?.localName} key={`from-network-${user.id}`}>
                                <Avatar src={getAvatarUrl(user)} alt={user.handle?.localName} sx={{
                          width: 28,
                          height: 28,
                          border: `1px solid ${theme.palette.background.paper}`
                        }} />
                              </Tooltip>)}
                          </Stack>
                          <Typography variant="body2">
                            From your network
                          </Typography>
                        </Stack> : null}
                    </Box>
                  </Stack>}
              </Box>
              <TopicTagChip label={topicData?.tag} color={topicData?.tag_color} variant={'filled'} data-sentry-element="TopicTagChip" data-sentry-source-file="TopTopicDesktopCard.tsx" />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" bgcolor={theme.palette.grey[100]} data-sentry-element="Box" data-sentry-source-file="TopTopicDesktopCard.tsx">
            <Typography variant="subtitle1" data-sentry-element="Typography" data-sentry-source-file="TopTopicDesktopCard.tsx">
              {topicData?.description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>;
};
// ** Hooks
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAccount } from 'wagmi';

// ** Utils & Types
import { DEFAULT_NETWORK, LIBREE_NETWORKS } from '../utils/constants/networks';
export type PossibleChainId = number | undefined;
export type NetworkValue = {
  isLoading: boolean;
  chainId: PossibleChainId;
  unsupportedNetwork: boolean;
};
const NetworkContext = createContext<NetworkValue>({} as NetworkValue);
type NetworkProviderProps = {
  children: ReactNode;
};

/**
 * Determine if the current network is supported
 * @param chainId id of the current network
 * @returns boolean indicating whether the network is supported or not
 */
export function isSupportedChainId(chainId: number): boolean {
  const isSupported = LIBREE_NETWORKS.some(network => network.id === chainId);
  return Boolean(isSupported);
}

/**
 * Network context provider
 */
export function NetworkProvider({
  children
}: NetworkProviderProps) {
  const [unsupportedNetwork, setUnsupportedNetwork] = useState(false);
  const [chainId, setChainId] = useState<PossibleChainId>();
  const {
    isConnected,
    chain
  } = useAccount();

  /**
   * Loading state value.
   */
  const isLoading = chainId === undefined;

  /**
   * Initial wagmi chain id.
   */
  const wagmiChainId = useMemo(() => chain?.id, []);

  /**
   * Checks if the user is connected to an unsupported network.
   */
  useEffect(() => {
    handleNetworks();
  }, [chain]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const handleChainId = (chainNumber: PossibleChainId) => {
    if (chainNumber === undefined) {
      setChainId(undefined);
      return;
    }
    const isSupported = isSupportedChainId(chainNumber);
    setUnsupportedNetwork(!isSupported);
    if (!isSupported) {
      setChainId(DEFAULT_NETWORK);
    } else {
      setChainId(chainNumber);
    }
  };
  const handleNetworks = () => {
    if (chainId !== undefined && chain === undefined) return;
    if (isConnected && chain === undefined) {
      return handleChainId(undefined);
    }
    if (isConnected && chain) {
      return handleChainId(chain.id);
    }
    if (chainId === undefined && wagmiChainId !== undefined) {
      return handleChainId(wagmiChainId);
    }
    handleChainId(DEFAULT_NETWORK);
  };

  /*************************************************
   *                 Return values                 *
   *************************************************/

  const value: NetworkValue = {
    isLoading,
    chainId,
    unsupportedNetwork
  };
  return <NetworkContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="NetworkProvider" data-sentry-source-file="network.tsx">{children}</NetworkContext.Provider>;
}
export function useHandleNetwork(): NonNullable<NetworkValue> {
  return useContext(NetworkContext);
}
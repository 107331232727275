// ** React & Next & MUI
import { useEffect, useState } from 'react';
import Link from 'next/link';
import { Avatar, Box, CircularProgress, Collapse, Paper, Skeleton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// ** Hooks
import { useLens } from '@/context/lens';
import { useBackend } from '@/context/backend';
import { useSession } from '@/context/session';

// ** Components & Utils
import ProfileHover from '@/components/shared/ProfileHover';
import BlankCard from '@/components/shared/BlankCard';
import { ProfileFragment } from '@lens-protocol/client';
import { getAvatarUrl } from '@/utils';
import { TopicWithTagColor } from '@/utils/constants/types';
import { TopicTagChip } from '../TopicTagChip';
export const MentionsSkeleton = () => <Stack direction={'row'} alignItems={'center'} gap={2} data-sentry-element="Stack" data-sentry-component="MentionsSkeleton" data-sentry-source-file="TopTopicMobileCard.tsx">
    <Box display="flex" flexDirection="column" gap={0.6} justifyContent={'space-between'} data-sentry-element="Box" data-sentry-source-file="TopTopicMobileCard.tsx">
      <Stack direction="row" spacing={1} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="TopTopicMobileCard.tsx">
        <Stack direction="row" spacing={-0.5} data-sentry-element="Stack" data-sentry-source-file="TopTopicMobileCard.tsx">
          <Skeleton variant="circular" width={28} height={28} data-sentry-element="Skeleton" data-sentry-source-file="TopTopicMobileCard.tsx" />
          <Skeleton variant="circular" width={28} height={28} data-sentry-element="Skeleton" data-sentry-source-file="TopTopicMobileCard.tsx" />
          <Skeleton variant="circular" width={28} height={28} data-sentry-element="Skeleton" data-sentry-source-file="TopTopicMobileCard.tsx" />
        </Stack>
        <Skeleton variant="text" width={'7rem'} data-sentry-element="Skeleton" data-sentry-source-file="TopTopicMobileCard.tsx" />
      </Stack>
    </Box>
  </Stack>;
type CommDashboardFilterViewProps = {
  isLoading: boolean;
  topicData?: TopicWithTagColor;
};
export const TopTopicMobileCard = ({
  isLoading,
  topicData
}: CommDashboardFilterViewProps) => {
  const theme = useTheme();
  const [isLoadingMentions, setIsLoadingMentions] = useState(false);
  const [topEngagers, setTopEngagers] = useState<ProfileFragment[]>([]);
  const [fromNetwork, setFromNetwork] = useState<ProfileFragment[]>([]);
  const [totalMentions, setTotalMentions] = useState<number>();
  const {
    fetchTopEngagers,
    fetchTopicMentions
  } = useBackend();
  const {
    getProfilesById
  } = useLens();
  const {
    user
  } = useSession();
  useEffect(() => {
    if (topicData?.id) {
      getTopicMentions(topicData.id);
    }
    return cleanState;
  }, [topicData, user.profileId]);
  const [isOpen, setIsOpen] = useState(false);
  const getTopicMentions = async (topicId: string) => {
    setIsLoadingMentions(true);
    try {
      const mentions = await fetchTopicMentions(topicId);
      setTotalMentions(Number(mentions.totalMentions));
      const engagersData = await fetchTopEngagers(topicId, user.profileId);
      const topEngagersIds = engagersData?.topEngagers?.map(engager => engager.profileId);
      const followedEngagersIds = engagersData?.followedEngagers?.map(engager => engager.profileId);
      const profilesToFetch = Array.from(new Set([...(topEngagersIds || []), ...(followedEngagersIds || [])]));
      if (profilesToFetch.length) {
        const allProfilesData = await getProfilesById(profilesToFetch);
        const topEngagersData = allProfilesData.items.filter(profile => topEngagersIds?.includes(profile.id));
        const followedEngagersData = allProfilesData.items.filter(profile => followedEngagersIds?.includes(profile.id));
        setTopEngagers(topEngagersData);
        setFromNetwork(followedEngagersData);
      }
    } catch (error) {
      console.log('Error while fetching topics data', error);
    } finally {
      setIsLoadingMentions(false);
    }
  };
  const cleanState = () => {
    setTotalMentions(undefined);
    setTopEngagers([]);
    setFromNetwork([]);
  };
  if (isLoading) return <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh'
  }}>
        <CircularProgress />
      </Box>;
  if (!topicData) return null;
  return <Box display="flex" flexDirection="column" gap={2} data-sentry-element="Box" data-sentry-component="TopTopicMobileCard" data-sentry-source-file="TopTopicMobileCard.tsx">
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={1} data-sentry-element="Box" data-sentry-source-file="TopTopicMobileCard.tsx">
        <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="TopTopicMobileCard.tsx">💡 Insight of the day</Typography>
      </Box>
      <Paper key={topicData.id} sx={{
      overflow: 'hidden',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.action.selected
      }
    }} data-sentry-element="Paper" data-sentry-source-file="TopTopicMobileCard.tsx">
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={0.5} sx={{
        p: 2,
        cursor: 'pointer',
        backgroundColor: theme.palette.action.hover
      }} onClick={() => setIsOpen(prev => !prev)} data-sentry-element="Box" data-sentry-source-file="TopTopicMobileCard.tsx">
          <Box display={'flex'} flexDirection={'column'} gap={{
          xs: 0.25,
          sm: 0
        }} data-sentry-element="Box" data-sentry-source-file="TopTopicMobileCard.tsx">
            <Typography variant="subtitle1" sx={{
            lineHeight: {
              xs: 1.2,
              sm: 1.5
            }
          }} data-sentry-element="Typography" data-sentry-source-file="TopTopicMobileCard.tsx">
              {topicData.topic}
            </Typography>
            <Typography variant="body2" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="TopTopicMobileCard.tsx">
              {topicData.short_description}
            </Typography>
          </Box>

          <Box display={'flex'} flexDirection={'column'} alignItems={'end'} justifyContent={'space-between'} gap={0.5} data-sentry-element="Box" data-sentry-source-file="TopTopicMobileCard.tsx">
            <TopicTagChip label={topicData.tag} color={topicData.tag_color} variant={'filled'} data-sentry-element="TopicTagChip" data-sentry-source-file="TopTopicMobileCard.tsx" />
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Box>
        </Box>

        <Collapse in={isOpen} timeout="auto" unmountOnExit data-sentry-element="Collapse" data-sentry-source-file="TopTopicMobileCard.tsx">
          <Box display="flex" flexDirection="column" gap={1} sx={{
          p: 3
        }} data-sentry-element="Box" data-sentry-source-file="TopTopicMobileCard.tsx">
            {totalMentions ? <Typography variant="h6">
                {totalMentions?.toLocaleString()} mentions
              </Typography> : <Skeleton variant="text" width={'7rem'} />}
            <Box display="flex" flexDirection="column" gap={2} justifyContent={'space-between'} data-sentry-element="Box" data-sentry-source-file="TopTopicMobileCard.tsx">
              {isLoadingMentions ? <MentionsSkeleton /> : <>
                  {topEngagers.length ? <Stack direction="row" spacing={1} alignItems="center">
                      <Stack direction="row" spacing={-0.5}>
                        {topEngagers.map((user, i) => <ProfileHover user={user} key={i}>
                            <Link href={`/profile/${user.id}`}>
                              <Avatar src={getAvatarUrl(user)} alt={user.handle?.localName} sx={{
                        width: 28,
                        height: 28,
                        border: `1px solid ${theme.palette.background.paper}`
                      }} />
                            </Link>
                          </ProfileHover>)}
                      </Stack>
                      <Typography variant="body2">Top engagers</Typography>
                    </Stack> : null}

                  {fromNetwork.length ? <Stack direction="row" spacing={1} alignItems="center">
                      <Stack direction="row" spacing={-0.5}>
                        {fromNetwork.map(user => <Tooltip title={user.handle?.localName} key={user.id}>
                            <Avatar src={getAvatarUrl(user)} alt={user.handle?.localName} sx={{
                      width: 28,
                      height: 28,
                      border: `1px solid ${theme.palette.background.paper}`
                    }} />
                          </Tooltip>)}
                      </Stack>
                      <Typography variant="body2">From your network</Typography>
                    </Stack> : null}
                </>}

              <BlankCard data-sentry-element="BlankCard" data-sentry-source-file="TopTopicMobileCard.tsx">
                <Box display="flex" flexDirection="column" gap={2} sx={{
                p: 3
              }} data-sentry-element="Box" data-sentry-source-file="TopTopicMobileCard.tsx">
                  <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="TopTopicMobileCard.tsx">{topicData?.topic}</Typography>
                  <Typography variant="subtitle1" data-sentry-element="Typography" data-sentry-source-file="TopTopicMobileCard.tsx">
                    {topicData?.description}
                  </Typography>
                </Box>
              </BlankCard>
            </Box>
          </Box>
        </Collapse>
      </Paper>
    </Box>;
};
// ** React & MUI
import { useState } from 'react';
import { Modal, Button, Stack, TextField, CircularProgress, Typography, useTheme, useMediaQuery } from '@mui/material';

// ** Components
import BaseModal from './base';

// ** Context
import { useModalsActions, useModalsState } from '@/context/modals';
import { useLens } from '@/context/lens';
import { useSession } from '@/context/session';
import { NotConnectedCase } from './not-connected';
import { textOnly } from '@lens-protocol/metadata';
import { useIPFSStorage } from '@/context/IPFSStorage';
import { APP_ID_HANDLEFINDER } from '@/utils/constants/literals';
export interface ProposalPayload {
  onSuccess: () => void;
}

/////////////////////////////////////
export const ProposalModal = () => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const {
    close,
    open
  } = useModalsActions();
  const {
    createProposal
  } = useModalsState();
  const {
    createPost
  } = useLens();
  const {
    uploadJSON
  } = useIPFSStorage();
  const {
    notLoggedIn
  } = useSession();
  const [proposal, setProposal] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleCloseModal = () => {
    close('createProposal');
    setProposal('');
  };
  const handleConfirmClick = async () => {
    setSubmitting(true);
    await handleSubmit();
  };
  const handleSubmit = async () => {
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
    }
    try {
      const metadata = textOnly({
        content: proposal,
        tags: ['Handlefinder:proposal'],
        hideFromFeed: true,
        appId: APP_ID_HANDLEFINDER
      });
      const cid = await uploadJSON(metadata);
      if (!cid) throw new Error('Error while getting post cid');
      const res = await createPost(cid);
      if (res?.isSuccess) {
        createProposal.data?.onSuccess();
      }
      handleCloseModal();
    } catch (err) {
      console.log('Error while creating the proposal: ', err);
    } finally {
      setSubmitting(false);
    }
  };
  return <>
      <Modal open={createProposal.isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-source-file="create-proposal.tsx">
        <BaseModal sx={{
        justifyContent: 'center',
        paddingTop: '2em',
        width: isMdScreen ? '80vw' : '55vw'
      }} data-sentry-element="BaseModal" data-sentry-source-file="create-proposal.tsx">
          <Stack spacing={4} display="flex" alignItems="center" height="100%" data-sentry-element="Stack" data-sentry-source-file="create-proposal.tsx">
            <Typography variant="h6" display="flex" justifyContent="center" data-sentry-element="Typography" data-sentry-source-file="create-proposal.tsx">
              Create your proposal
            </Typography>
            <TextField id="outlined-multiline-static" placeholder="Share your thoughts" value={proposal} sx={{
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[100] : '#f0f0f0'
          }} onChange={e => setProposal(e.target.value)} multiline fullWidth rows={4} data-sentry-element="TextField" data-sentry-source-file="create-proposal.tsx" />
            <Stack display="flex" flexDirection="row" justifyContent="center" gap={2} data-sentry-element="Stack" data-sentry-source-file="create-proposal.tsx">
              <Button color="success" size="large" disabled={submitting} onClick={handleConfirmClick} data-sentry-element="Button" data-sentry-source-file="create-proposal.tsx">
                {!submitting ? 'Post' : <CircularProgress size={20} />}
              </Button>
              <Button color="error" size="large" disabled={submitting} onClick={handleCloseModal} data-sentry-element="Button" data-sentry-source-file="create-proposal.tsx">
                Cancel
              </Button>
            </Stack>
          </Stack>
        </BaseModal>
      </Modal>
    </>;
};
import { useSession } from '@/context/session';
import { useFarcasterSigner, usePrivy } from '@privy-io/react-auth';
import { CastId } from '@standard-crypto/farcaster-js-hub-rest';
enum ReactionType {
  Like = 'REACTION_TYPE_LIKE',
  Recast = 'REACTION_TYPE_RECAST',
}
export const useFarcaster = () => {
  const {
    farcasterClient,
    farcasterSigner
  } = useSession();
  const {
    user: privyUser
  } = usePrivy();
  const {
    requestFarcasterSignerFromWarpcast
  } = useFarcasterSigner();
  const farcasterAccount = privyUser?.linkedAccounts.find(account => account.type === 'farcaster');
  const authorizeFarcaster = async () => {
    try {
      await requestFarcasterSignerFromWarpcast();
    } catch (error) {
      console.log('Error authorizing farcaster: ', error);
    }
  };
  const checkIsUserFollowing = async (targetFid: number) => {
    try {
      if (!farcasterSigner || !farcasterAccount?.fid) {
        throw new Error('Farcaster signer or fid not found');
      }
      const link = await farcasterClient.getLinkById(farcasterAccount.fid, targetFid);
      return link !== null;
    } catch (error) {
      console.log('Error checking if user is following: ', error);
    }
  };
  const followUser = async (targetFid: number) => {
    try {
      if (!farcasterSigner || !farcasterAccount?.fid) {
        throw new Error('Farcaster signer or fid not found');
      }
      const followUserResponse = await farcasterClient.followUser(targetFid, farcasterAccount.fid, farcasterSigner);
      return followUserResponse;
    } catch (error) {
      console.log('Error following user: ', error);
    }
  };
  const unfollowUser = async (targetFid: number) => {
    try {
      if (!farcasterSigner || !farcasterAccount?.fid) {
        throw new Error('Farcaster signer or fid not found');
      }
      const unfollowUserResponse = await farcasterClient.unfollowUser(targetFid, farcasterAccount.fid, farcasterSigner);
      return unfollowUserResponse;
    } catch (error) {
      console.log('Error unfollowing user: ', error);
    }
  };
  const getUserDataByFid = async (fid: number) => {
    try {
      const userData = await farcasterClient.listAllUserDataByFid(fid);
      return userData;
    } catch (error) {
      console.log('Error getting user data by fid: ', error);
    }
  };
  const publishCast = async (text: string) => {
    try {
      if (!farcasterSigner || !farcasterAccount?.fid) {
        throw new Error('Farcaster signer or fid not found');
      }
      const submitCastResponse = await farcasterClient.submitCast({
        text
      }, farcasterAccount.fid, farcasterSigner);
      return submitCastResponse;
    } catch (error) {
      console.log('Error publishing cast: ', error);
    }
  };
  const getCastById = async (castId: CastId) => {
    try {
      const castData = await farcasterClient.getCastById(castId);
      return castData;
    } catch (error) {
      console.log('Error getting cast data: ', error);
    }
  };
  const getReactionsByCast = async (targetFid: number, targetHash: string, type: 'like' | 'recast') => {
    try {
      const reactions = await farcasterClient.listReactionsByCast(targetFid, targetHash, type === 'like' ? ReactionType.Like : ReactionType.Recast);
      return reactions;
    } catch (error) {
      console.log('Error getting reactions by cast: ', error);
    }
  };
  const getUserReactionByCast = async (targetFid: number, targetHash: string, type: 'like' | 'recast') => {
    try {
      if (!farcasterAccount?.fid) {
        throw new Error('Farcaster fid not found');
      }
      const reaction = await farcasterClient.getReactionById({
        fid: farcasterAccount.fid,
        targetFid,
        targetHash,
        reactionType: type === 'like' ? ReactionType.Like : ReactionType.Recast
      });
      return reaction;
    } catch (error) {
      console.log('Error getting user reaction by cast: ', error);
    }
  };
  const addReaction = async (reactionType: 'like' | 'recast', castId: CastId) => {
    try {
      if (!farcasterSigner || !farcasterAccount?.fid) {
        throw new Error('Farcaster signer or fid not found');
      }
      const submitReactionResponse = await farcasterClient.submitReaction({
        type: reactionType,
        target: castId
      }, farcasterAccount.fid, farcasterSigner);
      return submitReactionResponse;
    } catch (error) {
      console.log('Error reacting to cast: ', error);
    }
  };
  const removeReaction = async (reactionType: 'like' | 'recast', castId: CastId) => {
    try {
      if (!farcasterSigner || !farcasterAccount?.fid) {
        throw new Error('Farcaster signer or fid not found');
      }
      const submitReactionResponse = await farcasterClient.removeReaction({
        type: reactionType,
        target: castId
      }, farcasterAccount.fid, farcasterSigner);
      return submitReactionResponse;
    } catch (error) {
      console.log('Error removing reaction from cast: ', error);
    }
  };
  const commentOnCast = async () => {
    try {
      if (!farcasterSigner || !farcasterAccount?.fid) {
        throw new Error('Farcaster signer or fid not found');
      }

      // comment on cast
    } catch (error) {
      console.log('Error commenting on cast: ', error);
    }
  };
  return {
    farcasterAccount,
    checkIsUserFollowing,
    followUser,
    unfollowUser,
    getUserDataByFid,
    publishCast,
    authorizeFarcaster,
    getCastById,
    getReactionsByCast,
    getUserReactionByCast,
    addReaction,
    removeReaction,
    commentOnCast
  };
};
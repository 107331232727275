// ** MUI Imports
import { Button, ButtonProps } from '@mui/material';

// ** Hooks
import { useModalsActions } from '@/context/modals';
type Props = {
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
  label?: string;
  beforeOpen?: () => void;
};
export const SignInBtn = ({
  variant = 'text',
  color = 'primary',
  size = 'medium',
  label = 'Sign In',
  beforeOpen
}: Props) => {
  const {
    open: openModal
  } = useModalsActions();
  const logInWithLens = () => {
    openModal('signInProcess');
  };
  return <Button variant={variant} color={color} size={size} onClick={() => {
    beforeOpen?.();
    logInWithLens();
  }} data-sentry-element="Button" data-sentry-component="SignInBtn" data-sentry-source-file="SignInWallet.tsx">
      {label}
    </Button>;
};
import { TradeType, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { TransactionState } from './providers'
import { ethers } from 'ethers'
import { erc20Abi } from 'viem'
import {
  getNetworkRpc,
  getUniswapContractAddress,
} from '@/utils/uniswap/constants'
import { fromReadableAmountRoute } from '@/utils/uniswap/utils'
import { SwapOptionsSwapRouter02, SwapRoute } from '@uniswap/smart-order-router'
const dynamicImport = () => import('@uniswap/smart-order-router')

export async function generateRoute(
  provider:
    | ethers.providers.JsonRpcProvider
    | ethers.providers.FallbackProvider,
  userAddress: string,
  tokenIn: Token,
  tokenOut: Token,
  amountIn: number
): Promise<SwapRoute | null> {
  const { SwapType, AlphaRouter } = await dynamicImport()

  const chainId = (await provider.getNetwork()).chainId
  const chainIdNumber = Number(chainId)

  const rpc = getNetworkRpc(chainId)
  if (!rpc) throw new Error(`RPC not found for ${chainId} network`)

  const jsonRpcProvider = new ethers.providers.JsonRpcProvider(rpc)

  const router = new AlphaRouter({
    chainId: chainIdNumber,
    provider: jsonRpcProvider,
  })

  const inputRouteAmount = CurrencyAmount.fromRawAmount(
    tokenIn,
    fromReadableAmountRoute(amountIn, tokenIn.decimals).toString()
  )

  const options: SwapOptionsSwapRouter02 = {
    recipient: userAddress,
    slippageTolerance: new Percent(50, 10_000),
    deadline: Math.floor(Date.now() / 1000 + 1800),
    type: SwapType.SWAP_ROUTER_02,
  }

  return await router.route(
    inputRouteAmount,
    tokenOut,
    TradeType.EXACT_INPUT,
    options
  )
}

export async function executeRoute(
  provider:
    | ethers.providers.JsonRpcProvider
    | ethers.providers.FallbackProvider,
  signer: ethers.Signer,
  route: SwapRoute,
  tokenIn: Token,
  userAddress: string,
  approvalAmount: number
): Promise<TransactionState> {
  if (!provider) {
    throw new Error('No provider found')
  }

  const tokenApproval = await getTokenTransferApprovalRoute(
    tokenIn,
    provider,
    signer,
    userAddress,
    approvalAmount
  )

  // Fail if transfer approvals do not go through
  if (tokenApproval !== TransactionState.Sent) {
    return TransactionState.Failed
  }

  const chainId = (await provider.getNetwork()).chainId
  const swapRouterAddress = getUniswapContractAddress(chainId, 'V3_SWAP_ROUTER')

  const response = await provider.call({
    to: swapRouterAddress,
    data: route.methodParameters?.calldata,
    value: route.methodParameters?.value,
    from: userAddress,
  })

  return response
}

export async function getTokenTransferApprovalRoute(
  token: Token,
  provider:
    | ethers.providers.JsonRpcProvider
    | ethers.providers.FallbackProvider,
  signer: ethers.Signer,
  userAddress: string,
  approvalAmount: number
): Promise<TransactionState> {
  if (!provider) {
    return TransactionState.Failed
  }

  try {
    const tokenContract = new ethers.Contract(token.address, erc20Abi, provider)

    const chainId = (await provider.getNetwork()).chainId
    const swapRouterAddress = getUniswapContractAddress(
      chainId,
      'V3_SWAP_ROUTER'
    )

    const tx = await tokenContract.populateTransaction.approve(
      swapRouterAddress,
      fromReadableAmountRoute(approvalAmount, token.decimals).toString()
    )

    const signedTx = await signer.sendTransaction(tx)
    const txReceipt = await provider.waitForTransaction(signedTx.hash)

    if (txReceipt && txReceipt.confirmations > 0) {
      return TransactionState.Sent
    } else {
      return TransactionState.Failed
    }
  } catch (e) {
    console.error(e)
    return TransactionState.Failed
  }
}

// ** React & Mui
import { Box, Card, Typography, Button, Stack, Avatar, Chip, useTheme, useMediaQuery, ChipProps } from '@mui/material';

// ** Context & Utils
import { useModalsActions } from '@/context/modals';
import { UniswapWidgetPayload } from '../modals/uniswap-widget';
import { getChainByNetworkName } from '@/utils/uniswap/constants';
import { useSession } from '@/context/session';
import { NotConnectedCase } from '../modals/not-connected';
import { UniswapTokenStatsData } from '@/types/custom';
import { IconArrowDownRight, IconArrowRight, IconArrowUpRight } from '@tabler/icons-react';
interface TokenProps {
  token: UniswapTokenStatsData;
}
const getNetworkChipColor = (network: string) => {
  const networkColors = {
    polygon: {
      bgcolor: '#8626D1',
      color: 'white'
    },
    arbitrum: {
      bgcolor: '#0043A9',
      color: '#9EC5FF'
    },
    optimism: {
      bgcolor: '#E4080A',
      color: 'white'
    },
    base: {
      bgcolor: '#0167FF',
      color: 'white'
    }
  };
  return networkColors[network as keyof typeof networkColors] || null;
};
const formatLargeNumber = (num: number): string => {
  const absNum = Math.abs(num);
  if (absNum >= 1e9) {
    return `${(num / 1e9).toFixed(1)}B`;
  } else if (absNum >= 1e6) {
    return `${(num / 1e6).toFixed(1)}M`;
  } else if (absNum >= 1e3) {
    return `${(num / 1e3).toFixed(1)}K`;
  }
  return num.toString();
};
const UniswapCard = ({
  token
}: TokenProps) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    open: openModal
  } = useModalsActions();
  const {
    notLoggedIn,
    connectedNotLogged
  } = useSession();
  const handleBuyToken = () => {
    if (notLoggedIn) {
      openModal('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      openModal('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    const tokenChain = getChainByNetworkName(token.chain);
    if (!tokenChain) return;
    const uniswapPayload: UniswapWidgetPayload = {
      chainId: tokenChain.id,
      tokenAddress: token.address,
      tokenDecimals: token.decimals,
      tokenSymbol: token.symbol,
      tokenImg: token.image ?? undefined
    };
    openModal('uniswapWidget', uniswapPayload);
  };
  const chipSx: ChipProps['sx'] = {
    fontStyle: 'italic',
    fontWeight: 700,
    px: 1,
    height: 28,
    ...getNetworkChipColor(token.chain),
    opacity: 0.9
  };
  const priceChanged24h = token.price.priceChangePercentage24h;
  return <Card sx={{
    position: 'relative',
    maxWidth: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
    minHeight: '14em',
    bgcolor: theme.palette.background.default,
    borderRadius: ' 1em',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      transition: 'background-color 0.3s'
    }
  }} className="hoverCard" data-sentry-element="Card" data-sentry-component="UniswapCard" data-sentry-source-file="UniswapCard.tsx">
      <Avatar src={token.image ?? 'images/icons/token.png'} alt={token.symbol} sx={{
      position: 'absolute',
      top: '-0.5rem',
      left: isXsScreen ? '1.5rem' : '2rem',
      transform: 'translateX(-50%)',
      width: isXsScreen ? '4.5rem' : '5rem',
      height: isXsScreen ? '4.5rem' : '5rem',
      zIndex: 1,
      backgroundColor: !token.image ? '#fff' : 'transparent',
      padding: !token.image ? '0.02px' : '0',
      borderRadius: '50%'
    }} data-sentry-element="Avatar" data-sentry-source-file="UniswapCard.tsx" />
      <Box sx={{
      p: 2
    }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} gap={{
        xs: 2,
        md: 3
      }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
          {/* Header */}
          <Box display="flex" alignItems="center" justifyContent="space-between" width={'100%'} gap={0.5} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
            <Box display={'flex'} flexDirection={'column'} sx={{
            paddingLeft: {
              xs: '2.5rem',
              sm: '3rem'
            }
          }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
              <Typography variant="h5" component="div" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                {token.symbol}
              </Typography>
              <Typography variant="caption" fontWeight={600} fontSize={{
              xs: 12,
              sm: 14
            }} color={'text.secondary'} sx={{
              display: 'flex',
              alignItems: 'center'
            }} data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                <span style={{
                color: priceChanged24h === 0 ? 'inherit' : priceChanged24h > 0 ? 'green' : 'red'
              }}>{`${priceChanged24h}%`}</span>
                {priceChanged24h === 0 ? <IconArrowRight size={16} /> : priceChanged24h > 0 ? <IconArrowUpRight size={16} /> : <IconArrowDownRight size={16} />}
                {`24hr`}
              </Typography>
            </Box>
            <Chip label={token.chain} size={isSmScreen ? 'small' : 'medium'} sx={chipSx} data-sentry-element="Chip" data-sentry-source-file="UniswapCard.tsx" />
          </Box>

          {/* Cards Grid */}
          <Box sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: {
            xs: 2,
            md: 3
          },
          width: '100%'
        }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
            {/* Sales Card */}
            <Box sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#39414e' : '#f2f2f',
            p: {
              xs: 1,
              md: 2
            },
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
              <Stack alignItems={'center'} spacing={1} data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                <Box textAlign="center" display="flex" flexDirection="column" gap={0.5} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
                  <Typography variant="body1" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                    Bought by
                  </Typography>
                  <Stack direction="row" justifyContent={'center'} gap={0.5} data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                    <Typography variant="h6" color="#2EE7D1" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                      {`>${Math.round(token.buyers).toLocaleString()}`}
                    </Typography>
                    <Typography data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">🚀</Typography>
                  </Stack>
                </Box>
                <Typography variant="body2" color="text.primary" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                  Lens users 24hs
                </Typography>
              </Stack>
            </Box>

            {/* Lens Users Card */}
            <Box sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#39414e' : '#f2f2f',
            p: 2,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
          }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
              <Typography variant="body1" component="div" fontWeight="bold" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                Market Cap <br />
                <Stack display="flex" justifyContent="center" gap={0.5} direction="row" data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                  <Typography variant="h6" marginTop="0.2em" style={{
                  fontWeight: 'bold',
                  color: '#2EE7D1'
                }} data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                    ${formatLargeNumber(Math.round(token.price.marketCap))}
                  </Typography>
                  <Typography data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">🔥</Typography>
                </Stack>
              </Typography>
            </Box>
          </Box>

          {/* Buy Button */}
          <Button variant="outlined" color="warning" size="large" sx={{
          width: '56%',
          textTransform: 'none',
          fontSize: '1rem',
          borderWidth: '2px',
          borderColor: '#ff9800',
          padding: '8px'
        }} onClick={handleBuyToken} data-sentry-element="Button" data-sentry-source-file="UniswapCard.tsx">
            Buy
          </Button>
        </Box>
      </Box>
    </Card>;
};
export default UniswapCard;
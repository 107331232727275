// ** React & MUI
import { useMemo } from 'react';
import { Avatar, Badge, Box, Button, CircularProgress, Divider, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IconArrowDown, IconCheck } from '@tabler/icons-react';

// ** Components & Utils
import { SwapContent } from './SwapContent';
import { Token } from '@uniswap/sdk-core';
import { getNetworkIcon, getTokenIcon } from '@/utils/uniswap/constants';
import { useHandleNetwork } from '@/context/network';
interface SwapReviewProps {
  handleSwapClose: () => void;
  currentStep: 'approve' | 'sign' | 'confirm';
  tokenIn: Token;
  amountInLabel: string;
  tokenOut: Token;
  tokenOutImg?: string;
  amountOutLabel: string;
  isPreview: boolean;
  handleApprove: () => void;
  handleSign: () => void;
  disabled: boolean;
  isLoading: boolean;
  showChecked: boolean;
  hide?: boolean;
}
export const SwapReview = ({
  handleSwapClose,
  currentStep,
  tokenIn,
  amountInLabel,
  tokenOut,
  tokenOutImg,
  amountOutLabel,
  handleApprove,
  handleSign,
  disabled,
  isLoading,
  showChecked,
  hide
}: SwapReviewProps) => {
  const theme = useTheme();
  const {
    chainId
  } = useHandleNetwork();
  const buttonLabel = currentStep === 'approve' ? 'Approve in wallet' : 'Confirm swap';
  const networkIconSrc = useMemo(() => {
    if (!chainId) return '';
    return getNetworkIcon(chainId);
  }, [chainId]);
  const tokenOutIconSrc = useMemo(() => {
    if (tokenOutImg) return tokenOutImg;
    if (tokenOut && tokenOut.symbol) return getTokenIcon(tokenOut.symbol);
    return 'images/icons/token.png';
  }, [tokenOut, tokenOutImg]);
  const handleBtnClick = useMemo(() => {
    if (currentStep === 'approve') {
      return handleApprove;
    } else if (currentStep === 'sign') {
      return handleSign;
    } else {
      return;
    }
  }, [currentStep]);
  return <>
      {/* Modal Header */}
      <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 2,
      paddingBottom: 1,
      borderBottom: `1px solid ${theme.palette.divider}`
    }} data-sentry-element="Box" data-sentry-source-file="SwapReview.tsx">
        <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="SwapReview.tsx">You're swapping</Typography>
        <IconButton onClick={handleSwapClose} data-sentry-element="IconButton" data-sentry-source-file="SwapReview.tsx">
          <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="SwapReview.tsx" />
        </IconButton>
      </Box>

      {/* Modal Content */}
      <Box display="flex" flexDirection="column" justifyContent={'space-between'} gap={1} sx={{
      padding: 2,
      height: '100%'
    }} data-sentry-element="Box" data-sentry-source-file="SwapReview.tsx">
        {/* Swap Input */}
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{
        marginX: 1
      }} data-sentry-element="Box" data-sentry-source-file="SwapReview.tsx">
          <Box display="flex" flexDirection="column" alignItems="flex-start" data-sentry-element="Box" data-sentry-source-file="SwapReview.tsx">
            <Typography variant="h6" fontWeight={600} fontSize={18} data-sentry-element="Typography" data-sentry-source-file="SwapReview.tsx">
              {amountInLabel}
            </Typography>
            <Typography variant="caption" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="SwapReview.tsx"></Typography>
          </Box>
          <Badge color={'info'} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }} overlap="circular" badgeContent={<Avatar src={networkIconSrc} sx={{
          width: 20,
          height: 20
        }} />} sx={{
          '.MuiBadge-badge': {
            padding: 0
          }
        }} data-sentry-element="Badge" data-sentry-source-file="SwapReview.tsx">
            <Avatar src={tokenIn.symbol ? getTokenIcon(tokenIn.symbol) : ''} sx={{
            width: 42,
            height: 42
          }} data-sentry-element="Avatar" data-sentry-source-file="SwapReview.tsx" />
          </Badge>
        </Box>

        <IconArrowDown size={25} color={theme.palette.divider} data-sentry-element="IconArrowDown" data-sentry-source-file="SwapReview.tsx" />

        {/* Swap Output */}
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{
        marginX: 1
      }} data-sentry-element="Box" data-sentry-source-file="SwapReview.tsx">
          <Box display="flex" flexDirection="column" alignItems="flex-start" data-sentry-element="Box" data-sentry-source-file="SwapReview.tsx">
            <Typography variant="h6" fontWeight={600} fontSize={18} data-sentry-element="Typography" data-sentry-source-file="SwapReview.tsx">
              {amountOutLabel}
            </Typography>
            <Typography variant="caption" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="SwapReview.tsx"></Typography>
          </Box>
          <Badge color={'info'} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }} overlap="circular" badgeContent={<Avatar src={networkIconSrc} sx={{
          width: 20,
          height: 20
        }} />} sx={{
          '.MuiBadge-badge': {
            padding: 0
          }
        }} data-sentry-element="Badge" data-sentry-source-file="SwapReview.tsx">
            <Avatar src={tokenOutIconSrc} sx={{
            width: 42,
            height: 42
          }} data-sentry-element="Avatar" data-sentry-source-file="SwapReview.tsx" />
          </Badge>
        </Box>

        <Divider data-sentry-element="Divider" data-sentry-source-file="SwapReview.tsx" />

        {/* Steps Section */}
        <SwapContent isPreview={false} currentStep={currentStep} networkCost={'$0.09'} rate={`${amountInLabel} ${tokenIn.symbol} = ${amountOutLabel} ${tokenOut.symbol}`} maxSlippageValue={hide ? undefined : '2.5'} data-sentry-element="SwapContent" data-sentry-source-file="SwapReview.tsx" />

        <Button variant="contained" fullWidth onClick={handleBtnClick} disabled={disabled} data-sentry-element="Button" data-sentry-source-file="SwapReview.tsx">
          {showChecked ? <IconCheck size={20} /> : isLoading ? <CircularProgress size={20} /> : buttonLabel}
        </Button>
      </Box>
    </>;
};
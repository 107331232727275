/* eslint @typescript-eslint/no-unused-vars: 0 */

// ** React && MUI
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box, Typography, IconButton, useTheme, Slide } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

// ** Hooks
import { useSession } from '@/context/session';
import { useEthersProvider, useEthersSigner } from '@/hooks/useEthersAdapters';
import { useWaitForTransactionReceipt } from 'wagmi';
import { useModalsActions } from '@/context/modals';
import { useHandleNetwork } from '@/context/network';

// ** Uniswap
import { Token } from '@uniswap/sdk-core';
import { generateRoute, getTokenTransferApprovalRoute } from '../libs/routing';
import { SwapRoute } from '@uniswap/smart-order-router';
import { getTokenAllowance } from '../libs/trading';

// ** Components
import { LoginWallet } from '@/components/ctaButtons/wallet/LoginWallet';
import { SignInBtn } from '@/components/ctaButtons/wallet/SignInWallet';
import { SwapSettings } from './SwapSettings';
import { SwapReview } from './SwapReview';
import { SwapInput } from './SwapInput';

// ** Utils & Types
import { createToken, getUniswapContractAddress, getUniswapTokensByNetwork, UNISWAP_TOKENS } from '@/utils/uniswap/constants';
import { TransactionState } from '../libs/providers';
import { StatusEnum as Status } from '@/types/custom';
import { polygon } from 'viem/chains';
import { UniswapWidgetPayload } from '@/components/modals/uniswap-widget';
export enum SwapWidgetState {
  IDLE = 'Idle',
  TO_REVIEW = 'ToReview',
  // quote success, trade creation idle
  TO_CONFIRM = 'ToConfirm',
  // trade creation success
  TO_APPROVE = 'ToApprove',
  // approve idle
  TO_SIGN = 'ToSign',
  // approve success, confirm idle
  SUCCESS = 'Success',

  // confirm success
}
const hideSettings = true;
const parseAmount = (amount: string) => amount === '' ? 0 : parseFloat(amount);
type SwapWidgetProps = {
  token?: UniswapWidgetPayload;
};
export const SwapWidget = ({
  token
}: SwapWidgetProps) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [swapOpen, setSwapOpen] = useState(false);
  const [tokenIn, setTokenIn] = useState<Token>();
  const [amountIn, setAmountIn] = useState<string>('');
  const [debouncedAmountIn, setDebouncedAmountIn] = useState<number>(parseAmount(amountIn));
  const [tokenOut, setTokenOut] = useState<Token>();
  const [amountOut, setAmountOut] = useState<string>('1');
  const [route, setRoute] = useState<SwapRoute | null>(null);
  const [quoteStatus, setQuoteStatus] = useState<Status>(Status.idle);
  const [tradeCreationStatus, setTradeCreationStatus] = useState<Status>(Status.idle);
  const [tradeStatus, setTradeStatus] = useState<Status>(Status.idle);
  const [approveStatus, setApproveStatus] = useState<Status>(Status.idle);
  const [signStatus, setSignStatus] = useState<Status>(Status.idle);
  const [confirmStatus, setConfirmStatus] = useState<Status>(Status.idle);
  const [txHash, setTxHash] = useState<string>();
  const {
    isSuccess: isWaitSuccess,
    isLoading: isWaitLoading
  } = useWaitForTransactionReceipt({
    hash: txHash as `0x`
  });
  const [settings, setSettings] = useState({
    maxSlip: 0.1,
    minsDeadline: 30
  });
  const [showChecked, setShowChecked] = useState(false);
  const theme = useTheme();
  const provider = useEthersProvider();
  const signer = useEthersSigner();
  const {
    close: closeModal
  } = useModalsActions();
  const {
    chainId
  } = useHandleNetwork();
  const tokens = getUniswapTokensByNetwork(chainId);
  const {
    connectedNotLogged,
    notLoggedIn,
    user
  } = useSession();
  const {
    address
  } = user;
  if (typeof window !== 'undefined') {
    // @ts-expect-error: TS does not recognize the 'Browser' property on the 'window' object
    window.Browser = {
      T: () => {}
    };
  }
  const widgetState = useMemo(() => {
    if (tradeCreationStatus === Status.success && approveStatus === Status.success && confirmStatus === Status.success) {
      return SwapWidgetState.SUCCESS;
    }
    if (tradeCreationStatus === Status.success && approveStatus === Status.success && confirmStatus === Status.idle) {
      return SwapWidgetState.TO_SIGN;
    }
    if (route && tradeCreationStatus === Status.success && (approveStatus === Status.idle || approveStatus === Status.error) && confirmStatus === Status.idle) {
      return SwapWidgetState.TO_APPROVE;
    }
    if (tradeCreationStatus === Status.success && approveStatus === Status.idle && (confirmStatus === Status.idle || confirmStatus === Status.error)) {
      return SwapWidgetState.TO_CONFIRM;
    }
    if (quoteStatus === Status.success && tradeCreationStatus === Status.idle) {
      return SwapWidgetState.TO_REVIEW;
    }
    return SwapWidgetState.IDLE;
  }, [tradeCreationStatus, quoteStatus, approveStatus, confirmStatus, route]);
  useEffect(() => {
    handleDefaultTokenIn();
    handleTokenOutReceived(token);
  }, [token]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedAmountIn(parseAmount(amountIn));
    }, 1000);
    return () => clearTimeout(handler);
  }, [amountIn]);
  useEffect(() => {
    if (debouncedAmountIn > 0 && tokenIn && tokenOut) {
      handleCreateRoute();
    }
  }, [debouncedAmountIn, tokenOut, tokenIn]);
  useEffect(() => {
    if (tradeCreationStatus === Status.success && route && approveStatus === Status.idle) {
      handleConfirmTrade();
    } else if (tradeCreationStatus === Status.success && approveStatus === Status.success && route !== null && signStatus === Status.idle) {
      handleExecuteRoute(route);
    } else if (tradeCreationStatus === Status.success && approveStatus === Status.success && signStatus === Status.loading && isWaitSuccess) {
      handleFinishSwap();
    }
  }, [approveStatus, route, tradeCreationStatus, signStatus, isWaitSuccess]);
  const cleanState = () => {
    setTradeCreationStatus(Status.idle);
    setTradeStatus(Status.idle);
    setRoute(null);
    setApproveStatus(Status.idle);
    setSignStatus(Status.idle);
    setConfirmStatus(Status.idle);
  };
  const handleSettingsOpen = () => setSettingsOpen(true);
  const handleSettingsClose = () => setSettingsOpen(false);
  const handleConfirmTrade = () => setSwapOpen(true);
  const handleSwapClose = () => {
    setSwapOpen(false);
    cleanState();
  };
  const handleSelectToken = (tokenSelected: Token) => {
    setTokenIn(tokenSelected);
  };
  const handleDefaultTokenIn = () => {
    const defaultTokenIn = tokens[0];
    setTokenIn(defaultTokenIn);
    return defaultTokenIn;
  };
  const handleTokenOutReceived = (tokenReceived?: UniswapWidgetPayload) => {
    if (!tokenReceived) return;
    const {
      tokenSymbol,
      chainId,
      tokenAddress,
      tokenDecimals
    } = tokenReceived;
    const createdToken = createToken(tokenSymbol, chainId, tokenAddress, tokenDecimals);
    if (!createdToken) return;
    setTokenOut(createdToken);
  };
  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      name,
      value
    } = event.target;
    const isValidInput = value === '' || /^[0-9]*\.?[0-9]*$/.test(value);
    if (isValidInput) {
      if (name === 'amountIn') {
        setAmountIn(value);
      } else if (name === 'amountOut') {
        setAmountOut(value);
      }
    }
  };
  const handleSettingsChange = (event: any) => {
    const {
      name,
      value
    } = event.target;
    setSettings(prevValue => {
      return {
        ...prevValue,
        [name]: Number(value)
      };
    });
  };
  const handleTokenSwap = () => {
    const newTokenIn = tokenOut || UNISWAP_TOKENS[polygon.id][0];
    const newTokenOut = tokenIn;
    setTokenIn(newTokenIn ?? undefined);
    setTokenOut(newTokenOut);
  };
  const handleCreateRoute = async () => {
    try {
      setTradeCreationStatus(Status.loading);
      if (!provider || !address) throw new Error('No provider found.');
      const numAmountIn = parseAmount(amountIn);
      const numAmountOut = parseAmount(amountOut);
      if (numAmountIn <= 0 || numAmountOut <= 0) {
        throw new Error('Amount must be bigger than zero');
      }
      if (!tokenIn || !tokenOut) {
        throw new Error('No token out found.');
      }
      const routeCreated = await generateRoute(provider, address, tokenIn, tokenOut, numAmountIn);
      if (!routeCreated) {
        throw new Error('No route created');
      }
      const exactQuote = routeCreated.trade.swaps[0].outputAmount.toExact();
      const exactNumber = Number(exactQuote);
      const fixedNumber = exactNumber < 1 ? exactNumber.toPrecision(3) : exactNumber.toFixed(2);
      setAmountOut(fixedNumber.toString());
      setRoute(routeCreated);
      setTradeCreationStatus(Status.success);
    } catch (error) {
      console.log(`Error creating route: ${error}`);
      setTradeCreationStatus(Status.error);
    }
  };
  const handleFinishSwap = () => {
    setShowChecked(true);
    setSignStatus(Status.success);
    setTimeout(async () => {
      setShowChecked(false);
      handleSwapClose();
      closeModal('uniswapWidget');
    }, 1500);
  };
  const handleApproveTrade = async () => {
    try {
      setTradeStatus(Status.loading);
      setApproveStatus(Status.loading);
      if (!provider || !signer) throw new Error('No provider found.');
      if (!address) throw new Error('No address found');
      if (route === null) throw new Error('Trade is undefined');
      if (!tokenIn) throw new Error('No token found');
      const numAmountIn = parseAmount(amountIn);
      if (numAmountIn <= 0) {
        throw new Error('Amount must be bigger than zero');
      }

      // Check if user already has allowance
      const currentAllowance = await getTokenAllowance(tokenIn, provider, address);
      if (currentAllowance && currentAllowance >= numAmountIn) {
        setApproveStatus(Status.success);
        return;
      }

      // Give approval to the router to spend the token
      const tokenApproval = await getTokenTransferApprovalRoute(tokenIn, provider, signer, address, numAmountIn);

      // Fail if transfer approvals do not go through
      if (tokenApproval !== TransactionState.Sent) {
        throw new Error('Transaction failed');
      }
      setApproveStatus(Status.success);
    } catch (error) {
      console.log(`Error approving route: ${error}`);
      setApproveStatus(Status.error);
    }
  };
  const handleExecuteRoute = async (swapToExecute: SwapRoute) => {
    try {
      setSignStatus(Status.loading);
      if (!address) {
        throw new Error('No address found');
      }
      if (!signer || !chainId) {
        throw new Error('Invalid signer or chain id.');
      }
      const swapRouterAddress = getUniswapContractAddress(chainId, 'V3_SWAP_ROUTER');
      const tx = {
        data: swapToExecute.methodParameters?.calldata,
        to: swapRouterAddress,
        value: swapToExecute?.methodParameters?.value,
        from: address
      };
      const signedTx = await signer.sendTransaction(tx);
      setTxHash(signedTx.hash);
    } catch (error) {
      console.log(`Error executing trade: ${error}`);
      setSignStatus(Status.error);
    }
  };
  const currentReviewStep = useMemo(() => {
    if (approveStatus === Status.success && (isWaitLoading || isWaitSuccess)) {
      return 'confirm';
    } else if (approveStatus === Status.loading) {
      return 'approve';
    }
    if (widgetState === SwapWidgetState.TO_CONFIRM) {
      return 'confirm';
    } else if (widgetState === SwapWidgetState.TO_APPROVE) {
      return 'approve';
    } else {
      return 'sign';
    }
  }, [widgetState, approveStatus, isWaitLoading, isWaitSuccess]);
  return <>
      <Box sx={{
      width: {
        xs: 300,
        sm: 350
      },
      padding: 2,
      borderRadius: 2,
      boxShadow: 3,
      textAlign: 'center',
      position: 'relative',
      overflow: 'hidden'
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {/* Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="index.tsx">Swap</Typography>
          <Box display="flex" alignItems="center" gap={1} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            {notLoggedIn && <LoginWallet variant={undefined} color="primary" size={'small'} />}
            {connectedNotLogged && <SignInBtn label="Login with Lens" size="small" variant="contained" color="primary" />}
            {!hideSettings && <IconButton onClick={handleSettingsOpen}>
                <SettingsIcon />
              </IconButton>}
          </Box>
        </Box>

        <SwapInput tokenIn={tokenIn} amountIn={amountIn} handleAmountChange={handleAmountChange} handleTokenSwap={handleTokenSwap} handleButtonClick={handleCreateRoute} quoteStatus={quoteStatus} tradeCreationStatus={tradeCreationStatus} tradeStatus={tradeStatus} amountOut={amountOut} tokenOut={tokenOut} tokenOutImg={token?.tokenImg} handleSelectToken={handleSelectToken} data-sentry-element="SwapInput" data-sentry-source-file="index.tsx" />

        <Slide direction="up" in={swapOpen} mountOnEnter unmountOnExit data-sentry-element="Slide" data-sentry-source-file="index.tsx">
          <Box sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: theme.palette.background.paper,
          boxShadow: 3,
          zIndex: 10,
          display: 'flex',
          flexDirection: 'column'
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            {tokenIn && tokenOut && <SwapReview isPreview={tradeStatus === Status.idle} handleSwapClose={handleSwapClose} currentStep={currentReviewStep} tokenIn={tokenIn} amountInLabel={amountIn} tokenOut={tokenOut} tokenOutImg={token?.tokenImg} amountOutLabel={amountOut.toLocaleString()} handleApprove={handleApproveTrade} handleSign={() => route ? handleExecuteRoute(route) : {}} disabled={approveStatus === Status.loading || signStatus === Status.loading || showChecked} showChecked={showChecked} isLoading={approveStatus === Status.loading || signStatus === Status.loading} hide={hideSettings} />}
          </Box>
        </Slide>

        {/* Settings Modal */}
        {!hideSettings && <Slide direction="up" in={settingsOpen} mountOnEnter unmountOnExit>
            <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: theme.palette.background.paper,
          boxShadow: 3,
          zIndex: 10,
          display: 'flex',
          flexDirection: 'column'
        }}>
              <SwapSettings handleSettingsClose={handleSettingsClose} settings={settings} handleSettingsChange={handleSettingsChange} />
            </Box>
          </Slide>}
      </Box>
    </>;
};
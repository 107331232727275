import {
  categories,
  categoryKeywords,
  ExtendedButtonColorProp,
} from './constants/types'
import { PROJECT_MODE } from './constants/api'
import { Theme } from '@mui/material'

export const isProductionMode = PROJECT_MODE === 'production'
export const isDevelopMode = PROJECT_MODE === 'development'

export const addRenderPropertyAndCategory = (interests: any) => {
  return interests.map((interestObj) => {
    let render = interestObj.interest.toLowerCase().replace(/_/g, ' ')
    render = render.replace(/\s+/g, ' ')

    const category = categories.find((cat) => {
      const keywords = categoryKeywords[cat]
      return keywords.some((keyword) => render.includes(keyword))
    })

    return { ...interestObj, render, category }
  })
}

const parseMentions = (text: string): string => {
  const pattern = /@\w+\/\w+/g

  const replacer = (match: string) => {
    const dynamicHandle = match.split('/')[1]
    return `<a href="${window.location.origin}/profile/handle/${dynamicHandle}" style="font-weight: bold; text-decoration: none; color: inherit;" onclick="event.stopPropagation();">@${dynamicHandle}</a>`
  }

  return text.replace(pattern, replacer)
}

const parseLineBreaks = (text: string) => {
  return text.replace(/\n/g, '<br>')
}

const convertUrlsToLinks = (text: string) => {
  const urlPathRegex = /<(https*:\/\/[^\s<>"]+)>|(https*:\/\/[^\s<>"]+)/g
  return text.replace(
    urlPathRegex,
    function (match, urlWithBrackets, urlWithoutBrackets) {
      const url = urlWithBrackets || urlWithoutBrackets
      const domainUrl = window.location.host

      const isOutsideApp = !url.includes(domainUrl)
      if (isOutsideApp) {
        return `<a href="${url}" target="_blank">${url}</a>`
      } else {
        return `<a href="${url}">${url}</a>`
      }
    }
  )
}

const parseBoldText = (text: string) => {
  return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
}

export const parseLensContent = (content: string) => {
  const parsedLineBreaks = parseLineBreaks(content)
  const parsedLinks = convertUrlsToLinks(parsedLineBreaks)
  const parsedMentions = parseMentions(parsedLinks)
  const parsedBoldText = parseBoldText(parsedMentions)
  return parsedBoldText
}

export const checkIfLivepeerGateway = (url: string) => {
  return url.includes('playback.livepeer.studio')
}

export const changeLensGateway = (url: string) => {
  const imageCID = getCidFromGateway(url)
  return imageCID ? nftStorageGateway(imageCID) : url
}

export const getCidFromGateway = (url: string) => {
  if (url.startsWith('ipfs://')) {
    return url.substring(7)
  }

  const regex = /\/ipfs\/(.+)$/
  const match = url.match(regex)

  if (match && match[1]) {
    return match[1]
  }
  return null
}

export const nftStorageGateway = (cid: string) =>
  cid ? `https://${cid}.ipfs.nftstorage.link` : ''

export const getAvatarUrl = (user: any) => {
  if (!user) return '/images/profile/defaultUser.webp'

  const { metadata } = user
  if (!metadata) return '/images/profile/defaultUser.webp'

  if (metadata?.picture?.optimized?.uri) {
    return metadata?.picture?.optimized?.uri
  } else if (metadata?.picture?.image?.raw.uri) {
    return metadata?.picture?.image?.raw.uri
  } else if (metadata?.picture?.raw?.uri) {
    return metadata?.picture?.raw?.uri
  } else return '/images/profile/defaultUser.webp'
}

export const arweaveGateWay = (id: string) => {
  const cleanedId = id.replace(/^ar:\/\//, '')
  return `https://arweave.net/${cleanedId}`
}

/**
 *
 * @param address The complete address to truncate
 * @param startLength The number of characters to show at the start of the address
 * @param endLength The number of characters to show at the end of the address
 * @returns The truncated address or an empty string if the address is not defined
 * @example truncateAddress("0x1234567890abcdef1234567890abcdef12345678") => "0x12345...5678"
 */
export const truncateAddress = (
  address: `0x${string}`,
  startLength = 5,
  endLength = 4
) => {
  if (!address) return ''
  const truncatedStart = address.slice(0, startLength)
  const truncatedEnd = address.slice(-endLength)
  return `${truncatedStart}...${truncatedEnd}`
}

export const getRankingEmoji = (ranking: number | undefined) => {
  switch (ranking) {
    case 1:
      return '🥇'
    case 2:
      return '🥈'
    case 3:
      return '🥉'
    default:
      return ''
  }
}

export const getTagButtonStyles = (
  theme: Theme,
  btnColor: ExtendedButtonColorProp,
  isSelected: boolean
): { borderRadius: '3rem'; color: string; bgcolor: string } => {
  const isDarkMode = theme.palette.mode === 'dark'
  const baseStyles = { borderRadius: '3rem', fontWeight: 600 }
  if (btnColor === 'pink') {
    return {
      ...baseStyles,
      ...(isSelected
        ? {
            color: 'white',
            bgcolor: '#FF1493',
            ':hover': {
              bgcolor: '#DB1681',
            },
          }
        : {
            color: '#FF1493',
            bgcolor: 'inherit',
            ':hover': {
              color: 'white',
              bgcolor: '#FF1493',
              border: '1px solid #FF1493',
              transition: 'background-color 250ms, color 250ms',
            },
          }),
    }
  }
  if (btnColor === 'dark-red') {
    return {
      ...baseStyles,
      ...(isSelected
        ? {
            color: 'white',
            bgcolor: '#BF2A02',
            ':hover': {
              bgcolor: '#A82501',
            },
          }
        : {
            color: theme.palette.error.dark,
            bgcolor: 'inherit',
            ':hover': {
              color: 'white',
              bgcolor: '#BF2A02',
              border: '1px solid #BF2A02',
              transition: 'background-color 250ms, color 250ms',
            },
          }),
    }
  }
  if (btnColor === 'white') {
    return {
      ...baseStyles,
      ...(isSelected
        ? {
            color: isDarkMode ? theme.palette.background.paper : 'white',
            bgcolor: isDarkMode ? '#EAEFF4' : 'black',
            ':hover': {
              bgcolor: isDarkMode ? undefined : theme.palette.grey[800],
              transition: 'background-color 250ms',
            },
          }
        : {
            color: 'inherit',
            bgcolor: 'inherit',
          }),
    }
  }
  if (btnColor === 'grey') {
    return {
      ...baseStyles,
      ...(isSelected
        ? {
            color: 'white',
            bgcolor: isDarkMode
              ? theme.palette.grey[200]
              : theme.palette.grey[400],
            ':hover': {
              bgcolor: isDarkMode
                ? theme.palette.grey[300]
                : theme.palette.grey[500],
              transition: 'background-color 250ms',
            },
          }
        : {
            color: isDarkMode
              ? theme.palette.grey[300]
              : theme.palette.grey[400],
            bgcolor: 'inherit',
            ':hover': {
              bgcolor: isDarkMode
                ? theme.palette.grey[100]
                : theme.palette.grey[400],
              color: isDarkMode ? undefined : 'white',
              transition: 'background-color 250ms, color 250ms',
            },
          }),
    }
  }
  return baseStyles
}

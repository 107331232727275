import React from 'react';
import Link from 'next/link';

// mui imports
import { ListItemIcon, List, styled, ListItemText, Chip, useTheme, Typography, ListItemButton } from '@mui/material';
import { AppState, useSelector } from '@/store/Store';
import { useTranslation } from 'react-i18next';
type NavGroup = {
  [x: string]: any;
  id?: string;
  navlabel?: boolean;
  subheader?: string;
  title?: string;
  icon?: any;
  href?: any;
  children?: NavGroup[];
  chip?: string;
  chipColor?: any;
  variant?: string | any;
  external?: boolean;
  level?: number;
  onClick?: React.MouseEvent<HTMLButtonElement, MouseEvent>;
};
interface ItemType {
  item: NavGroup;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  hideMenu?: any;
  level?: number | any;
  pathDirect: string;
}
const NavItem = ({
  item,
  level,
  pathDirect,
  hideMenu,
  onClick
}: ItemType) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const Icon = item?.icon;
  const theme = useTheme();
  const {
    t
  } = useTranslation();
  const itemIcon = level > 1 ? <Icon stroke={1.5} size="1rem" /> : <Icon stroke={1.5} size="1.3rem" />;
  const ListItemStyled = styled(ListItemButton)(() => ({
    whiteSpace: 'nowrap',
    marginBottom: '2px',
    padding: '8px 10px',
    borderRadius: `${customizer.borderRadius}px`,
    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
    color: level > 1 && pathDirect === item?.href ? `${theme.palette.primary.main}!important` : theme.palette.text.secondary,
    paddingLeft: hideMenu ? '10px' : level > 2 ? `${level * 15}px` : '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
      }
    }
  }));
  return <List component="li" disablePadding key={item?.id && item.title} data-sentry-element="List" data-sentry-component="NavItem" data-sentry-source-file="index.tsx">
      <Link href={item.href} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <ListItemStyled
      // {...listItemProps}
      disabled={item?.disabled} selected={pathDirect === item?.href} onClick={onClick} data-sentry-element="ListItemStyled" data-sentry-source-file="index.tsx">
          <ListItemIcon sx={{
          minWidth: '36px',
          p: '3px 0',
          color: level > 1 && pathDirect === item?.href ? `${theme.palette.primary.main}!important` : 'inherit'
        }} data-sentry-element="ListItemIcon" data-sentry-source-file="index.tsx">
            {itemIcon}
          </ListItemIcon>
          <ListItemText data-sentry-element="ListItemText" data-sentry-source-file="index.tsx">
            {hideMenu ? '' : <>{t(`${item?.title}`)}</>}
            <br />
            {item?.subtitle ? <Typography variant="caption">
                {hideMenu ? '' : item?.subtitle}
              </Typography> : ''}
          </ListItemText>

          {!item?.chip || hideMenu ? null : <Chip color={item?.chipColor} variant={item?.variant ? item?.variant : 'filled'} size="small" label={item?.chip} />}
        </ListItemStyled>
      </Link>
    </List>;
};
export default NavItem;
import { providers } from 'ethers';
import { useMemo } from 'react';
import type { Account, Chain, Client, Transport } from 'viem';
import { polygon } from 'viem/chains';
import { type Config, useClient, useConnectorClient } from 'wagmi';

// Client -> Ethers Provider ===============================================

export function clientToProvider(client: Client<Transport, Chain>) {
  const {
    chain,
    transport
  } = client;
  const network = {
    chainId: chain?.id || polygon.id,
    name: chain?.name || polygon.name,
    ensAddress: chain?.contracts?.ensRegistry?.address
  };
  if (transport.type === 'fallback') return new providers.FallbackProvider((transport.transports as ReturnType<Transport>[]).map(({
    value
  }) => new providers.JsonRpcProvider(value?.url, network)));
  return new providers.JsonRpcProvider(transport.url, network);
}

/** Action to convert a viem Client to an ethers.js Provider. */
export function useEthersProvider({
  chainId
}: {
  chainId?: number;
} = {}) {
  const client = useClient<Config>({
    chainId
  });
  return useMemo(() => client ? clientToProvider(client) : undefined, [client]);
}

// Client -> Signer ========================================================

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const {
    account,
    chain,
    transport
  } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({
  chainId
}: {
  chainId?: number;
} = {}) {
  const {
    data: client
  } = useConnectorClient<Config>({
    chainId
  });
  return useMemo(() => client ? clientToSigner(client) : undefined, [client]);
}
//
// Profile dropdown
//
interface ProfileType {
  href: string
  title: string
  subtitle: string
  icon: any
  hide: boolean
  isProductionMode: boolean
}
const profile: ProfileType[] = []

export { profile }

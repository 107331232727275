import { uniqueId } from 'lodash'
import {
  IconUsersGroup,
  IconAward,
  IconTrophy,
  IconPercentage33,
  IconWorldDollar,
  IconUsers,
} from '@tabler/icons-react'
import { isProductionMode } from '@/utils'

interface MenuitemsType {
  [x: string]: any
  id?: string
  navlabel?: boolean
  subheader?: string
  title?: string
  icon?: any
  href?: string
  children?: MenuitemsType[]
  chip?: string
  chipColor?: string
  variant?: string
  external?: boolean
  isProductionMode: boolean
  hide?: boolean
}

const items: MenuitemsType[] = [
  {
    navlabel: true,
    subheader: 'Dashboard',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Overview',
    icon: IconPercentage33,
    href: '/',
    chipColor: 'secondary',
    isProductionMode: true,
  },
  {
    navlabel: true,
    subheader: 'TRENDS & GEMS',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Intelligence',
    icon: IconUsers,
    href: '/intelligence',
    chipColor: 'secondary',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'DeFi',
    icon: IconWorldDollar,
    href: '/defi',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Top creators',
    icon: IconTrophy,
    href: '/top-creators',
    isProductionMode: false,
  },

  {
    navlabel: true,
    subheader: 'WEB3 SOCIAL',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'People and communities',
    icon: IconUsersGroup,
    href: '/people',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'NFT & Utiliites',
    icon: IconAward,
    href: '/collects&Mints',
    isProductionMode: true,
  },
]

const Menuitems = isProductionMode
  ? items.filter((item) => item.isProductionMode)
  : items

export default Menuitems

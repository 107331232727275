import {
  Currency,
  CurrencyAmount,
  Percent,
  Token,
  TradeType,
} from '@uniswap/sdk-core'
import {
  Pool,
  Route,
  SwapOptions,
  SwapQuoter,
  SwapRouter,
  Trade,
} from '@uniswap/v3-sdk'
import { ethers } from 'ethers'
import JSBI from 'jsbi'

import { getUniswapContractAddress } from '@/utils/uniswap/constants'
import { sendTransaction, TransactionState } from './providers'
import { fromReadableAmount } from '@/utils/uniswap/utils'
import { getPoolInfo } from './pool'
import { erc20Abi } from 'viem'

export type TokenTrade = Trade<Token, Token, TradeType>

// Trading Functions

export async function createTrade(
  provider: ethers.providers.JsonRpcProvider | ethers.providers.FallbackProvider,
  tokenIn: Token,
  tokenOut: Token,
  poolFee: number,
  amountIn: number
): Promise<TokenTrade> {
  const poolInfo = await getPoolInfo(provider, tokenIn, tokenOut, poolFee)

  const pool = new Pool(
    tokenIn,
    tokenOut,
    poolFee,
    poolInfo.sqrtPriceX96.toString(),
    poolInfo.liquidity.toString(),
    Number(poolInfo.tick)
  )

  const swapRoute = new Route([pool], tokenIn, tokenOut)

  const amountOut = await getOutputQuote(swapRoute, provider, tokenIn, amountIn)

  const inputAmount = CurrencyAmount.fromRawAmount(
    tokenIn,
    fromReadableAmount(amountIn, tokenIn.decimals).toString()
  )

  const outputAmount = CurrencyAmount.fromRawAmount(
    tokenOut,
    JSBI.BigInt(amountOut)
  )

  const uncheckedTrade = Trade.createUncheckedTrade({
    route: swapRoute,
    inputAmount,
    outputAmount,
    tradeType: TradeType.EXACT_INPUT,
  })

  return uncheckedTrade
}

export async function executeTrade(
  trade: TokenTrade,
  provider: ethers.providers.JsonRpcProvider | ethers.providers.FallbackProvider,
  signer: ethers.Signer,
  tokenIn: Token,
  userAddress: string
): Promise<TransactionState> {
  const bigIntApprovalAmount = trade.inputAmount.denominator
  const approvalAmount = JSBI.BigInt(bigIntApprovalAmount)

  // Give approval to the router to spend the token
  const tokenApproval = await getTokenTransferApproval(
    tokenIn,
    provider,
    userAddress,
    Number(approvalAmount)
  )
  // Fail if transfer approvals do not go through
  if (tokenApproval !== TransactionState.Sent) {
    return TransactionState.Failed
  }

  const options: SwapOptions = {
    slippageTolerance: new Percent(50, 10_000), // 50 bips, or 0.50%
    deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from the current Unix time
    recipient: userAddress,
  }

  const methodParameters = SwapRouter.swapCallParameters([trade], options)

  const chainId = (await provider.getNetwork()).chainId
  const swapRouterAddress = getUniswapContractAddress(chainId, 'SWAP_ROUTER')

  const tx = {
    data: methodParameters.calldata,
    to: swapRouterAddress,
    value: methodParameters.value,
    from: userAddress,
  }

  const signedTx = await signer.sendTransaction(tx)

  const txReceipt = await provider.waitForTransaction(signedTx.hash)

  if (txReceipt) {
    return TransactionState.Sent
  } else {
    return TransactionState.Failed
  }
}

// Helper Quoting and Pool Functions

async function getOutputQuote(
  route: Route<Currency, Currency>,
  provider: ethers.providers.JsonRpcProvider | ethers.providers.FallbackProvider,
  tokenIn: Token,
  amountIn: number
) {
  if (!provider) {
    throw new Error('Provider required to get pool state')
  }
  const amount = fromReadableAmount(amountIn, tokenIn.decimals).toString()

  const { calldata } = await SwapQuoter.quoteCallParameters(
    route,
    CurrencyAmount.fromRawAmount(tokenIn, amount),
    TradeType.EXACT_INPUT,
    {
      useQuoterV2: true,
    }
  )

  const chainId = (await provider.getNetwork()).chainId
  const quoterAddress = getUniswapContractAddress(chainId, 'V2_QUOTER')

  const quoteCallReturnData = await provider.call({
    to: quoterAddress,
    data: calldata,
  })

  return ethers.utils.defaultAbiCoder.decode(['uint256'], quoteCallReturnData)
}

/**
 * Retrieves the current allowance amount for a token.
 * @param token - The token object containing its address and decimals.
 * @param provider - The JSON-RPC or fallback provider to interact with the blockchain.
 * @param userAddress - The address of the token owner.
 * @returns The current allowance amount as a BigNumber.
 */
export async function getTokenAllowance(
  token: Token,
  provider: ethers.providers.JsonRpcProvider | ethers.providers.FallbackProvider,
  userAddress: string
): Promise<number | undefined> {
  if (!provider) return

  try {
    const tokenContract = new ethers.Contract(token.address, erc20Abi, provider)
    const chainId = (await provider.getNetwork()).chainId

    // Get the address of the swap router (or other spender) for the current chain
    const swapRouterAddress = getUniswapContractAddress(chainId, 'SWAP_ROUTER')

    // Retrieve the allowance amount
    const allowance = await tokenContract.allowance(
      userAddress,
      swapRouterAddress
    )

    return Number(allowance)
  } catch (e) {
    console.error('Failed to fetch token allowance:', e)
  }
}

export async function getTokenTransferApproval(
  token: Token,
  provider: ethers.providers.JsonRpcProvider | ethers.providers.FallbackProvider,
  userAddress: string,
  approvalAmount: number
): Promise<TransactionState> {
  if (!provider) {
    return TransactionState.Failed
  }

  try {
    const tokenContract = new ethers.Contract(token.address, erc20Abi, provider)

    const chainId = (await provider.getNetwork()).chainId
    const swapRouterAddress = getUniswapContractAddress(chainId, 'SWAP_ROUTER')

    const transaction = await tokenContract.populateTransaction.approve(
      swapRouterAddress,
      fromReadableAmount(approvalAmount, token.decimals).toString()
    )

    return sendTransaction(
      {
        ...transaction,
        from: userAddress,
      },
      provider
    )
  } catch (e) {
    console.error(e)
    return TransactionState.Failed
  }
}

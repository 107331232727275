// React & Next
import React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import { Box, CircularProgress, CssBaseline } from '@mui/material';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { ThemeSettings } from '@/theme/Theme';

// Store
import createEmotionCache from '@/createEmotionCache';
import { Provider } from 'react-redux';
import Store, { AppState, useDispatch, useSelector } from '@/store/Store';
import NextNProgress from 'nextjs-progressbar';
import { setDarkMode } from '@/store/customizer/CustomizerSlice';

// Layouts
import BlankLayout from '@/layouts/blank/BlankLayout';
import FullLayout from '@/layouts/full/FullLayout';
import RTL from '@/layouts/full/shared/customizer/RTL';
import '@/utils/i18n';

// CSS FILES
import './app.scss';
import 'react-farcaster-embed/dist/styles.css';

// Providers
import { BackendProvider } from '@/context/backend';
import { LensProvider } from '@/context/lens';
import { SessionProvider } from '@/context/session';
import { WagmiProvider } from '@privy-io/wagmi';
import { ModalsProvider } from '@/context/modals';
import { NetworkProvider } from '@/context/network';
import { IPFSStorageProvider } from '@/context/IPFSStorage';
import { ZoraProvider } from '@/context/zora';
import { PrivyProvider } from '@privy-io/react-auth';
import { PodsProvider } from '@/context/pods';
import { OnlyMyNetworkProvider } from '@/context/onlyNextwork';
import { CategoryFilterProvider } from '@/context/categoryFilter';

// Analytics
import Hotjar from '@hotjar/browser';
import { HOTJAR_PROJECT_ID, PRIVY_APP_ID } from '@/utils/constants/api';
import { GoogleAnalytics } from 'nextjs-google-analytics';

// Wallet
import { privyConfig, wagmiConfig } from '@/components/config/wallet.config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient();
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}
const layouts: any = {
  Blank: BlankLayout
};
const MyApp = (props: MyAppProps) => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps
  }: any = props;
  const theme = ThemeSettings();
  const dispatch = useDispatch();
  const customizer = useSelector((state: AppState) => state.customizer);
  const Layout = layouts[Component.layout] || FullLayout;
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (HOTJAR_PROJECT_ID !== 0) {
      Hotjar.init(HOTJAR_PROJECT_ID, 6);
    }
    const mode = localStorage.getItem('activeMode');
    if (mode) {
      dispatch(setDarkMode(mode));
    }
    setLoading(true);
  }, [dispatch]);
  return <SessionProvider data-sentry-element="SessionProvider" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
      <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
        <BackendProvider data-sentry-element="BackendProvider" data-sentry-source-file="_app.tsx">
          <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
            <meta name="viewport" content="initial-scale=1, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
            <title>Handle finder</title>
          </Head>
          <NextNProgress color="#5D87FF" data-sentry-element="NextNProgress" data-sentry-source-file="_app.tsx" />
          <OnlyMyNetworkProvider data-sentry-element="OnlyMyNetworkProvider" data-sentry-source-file="_app.tsx">
            <CategoryFilterProvider data-sentry-element="CategoryFilterProvider" data-sentry-source-file="_app.tsx">
              <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
                <RTL direction={customizer.activeDir} data-sentry-element="RTL" data-sentry-source-file="_app.tsx">
                  <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="_app.tsx" />
                  {loading ? <Layout>
                      <Component {...pageProps} />
                    </Layout> : <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100vh'
                }}>
                      <CircularProgress />
                    </Box>}
                </RTL>
              </ThemeProvider>
            </CategoryFilterProvider>
          </OnlyMyNetworkProvider>
        </BackendProvider>
      </CacheProvider>
    </SessionProvider>;
};
const MyAppWrapper = (props: MyAppProps) => <Provider store={Store} data-sentry-element="Provider" data-sentry-component="MyAppWrapper" data-sentry-source-file="_app.tsx">
    <PrivyProvider appId={PRIVY_APP_ID} config={privyConfig} data-sentry-element="PrivyProvider" data-sentry-source-file="_app.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
        <WagmiProvider config={wagmiConfig} data-sentry-element="WagmiProvider" data-sentry-source-file="_app.tsx">
          <IPFSStorageProvider data-sentry-element="IPFSStorageProvider" data-sentry-source-file="_app.tsx">
            <NetworkProvider data-sentry-element="NetworkProvider" data-sentry-source-file="_app.tsx">
              <ZoraProvider data-sentry-element="ZoraProvider" data-sentry-source-file="_app.tsx">
                <ModalsProvider data-sentry-element="ModalsProvider" data-sentry-source-file="_app.tsx">
                  <LensProvider data-sentry-element="LensProvider" data-sentry-source-file="_app.tsx">
                    <PodsProvider data-sentry-element="PodsProvider" data-sentry-source-file="_app.tsx">
                      <GoogleAnalytics trackPageViews data-sentry-element="GoogleAnalytics" data-sentry-source-file="_app.tsx" />
                      <MyApp {...props} data-sentry-element="MyApp" data-sentry-source-file="_app.tsx" />
                    </PodsProvider>
                  </LensProvider>
                </ModalsProvider>
              </ZoraProvider>
            </NetworkProvider>
          </IPFSStorageProvider>
        </WagmiProvider>
      </QueryClientProvider>
    </PrivyProvider>
  </Provider>;
export default MyAppWrapper;
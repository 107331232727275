import axios from 'axios'
import { ProfileFragment } from '@lens-protocol/client'
import { CredentialType, LensClientStorageValue } from '../types/auth/auth'
import { SignInStatus, UserProfileData } from '../types/custom'
import { LensAuth } from './types'
import { isProductionMode } from '.'
import { HANDLE_PREFIX } from './constants/literals'
import { ethers } from 'ethers'
import erc1155Abi from '@/utils/abis/erc1155.json'
import { ConnectedWallet } from '@privy-io/react-auth'

const getLocalStorage = (key: string) => {
  const stored = localStorage.getItem(key)
  if (stored === 'undefined') return undefined
  return stored ? JSON.parse(stored) : undefined
}

const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const getSignInStatus = () => {
  return getLocalStorage('signInStatus') as SignInStatus | undefined
}

export const setSignInStatus = (value: SignInStatus) => {
  setLocalStorage('signInStatus', value)
}

export const removeSignInStatus = () => {
  removeLocalStorage('signInStatus')
}

/**
 * Wipes the private keys from the local storage
 * @param walletAddress - The address to wipe the credentials from.
 * @param type - The type of the credential to wipe.
 */
export const wipeCredentials = (
  walletAddress: string,
  type: CredentialType
) => {
  const environment = isProductionMode ? 'production' : 'development'
  const key = `${environment}.credentials:${walletAddress?.toLowerCase()}`
  const stored = localStorage.getItem(key)
  if (!stored) return

  const storedJSON = JSON.parse(stored)
  if (type === CredentialType.all) {
    localStorage.removeItem(key)
    return
  }

  if (storedJSON[type]) delete storedJSON[type]

  localStorage.setItem(key, JSON.stringify(storedJSON))
}

export const generateLensSignature = async (
  auth: (Id?: string | undefined) => Promise<LensAuth | undefined>,
  id?: string
): Promise<string | null> => {
  const authLens = await auth(id)
  if (!authLens) throw new Error('No lens auth')
  const jwt = localStorage.getItem('jwt')
  return jwt
}

export const formatLensFragmentData = (
  data: ProfileFragment | null | undefined
) => {
  return {
    profileId: data?.id,
    handle: data?.handle?.localName,
    name: data?.metadata?.displayName,
    bio: data?.metadata?.bio,
    picture: data?.metadata?.picture?.optimized?.uri,
    coverPicture: data?.metadata?.coverPicture?.optimized?.uri,
    followers: data?.stats.followers,
    following: data?.stats.following,
    posts: data?.stats.posts,
  } as UserProfileData
}

/**
 * Stores the private keys in the local storage
 * @param walletAddress - The address to store the credentials for.
 * @param type - The type of the credential to store.
 * @param keys - The private keys to store.
 */
export const storeCredentials = (
  walletAddress: string,
  type: Exclude<CredentialType, CredentialType.all>,
  value: any
) => {
  const environment = isProductionMode ? 'production' : 'development'
  const key = `${environment}.credentials:${walletAddress?.toLowerCase()}`
  const stored = localStorage.getItem(key)

  const storedJSON = stored ? JSON.parse(stored) : {}

  storedJSON[type] = value

  localStorage.setItem(key, JSON.stringify(storedJSON))
}

/**
 * Gets the private keys from the local storage
 * @param walletAddress - The address to get the credentials from.
 * @param type - The type of the credential needed.
 * @returns Private keys for the given wallet address and type.
 */
export const loadCredentials = (
  walletAddress: string,
  type: Exclude<CredentialType, CredentialType.all>
) => {
  const key = `credentials:${walletAddress?.toLowerCase()}`
  const stored = localStorage.getItem(key)
  if (stored) {
    localStorage.removeItem(key)
  }

  const environment = isProductionMode ? 'production' : 'development'
  const keyWithEnv = `${environment}.credentials:${walletAddress?.toLowerCase()}`
  const storedWithEnv = localStorage.getItem(keyWithEnv)
  if (!storedWithEnv) return null
  const storedJSON = JSON.parse(storedWithEnv)

  return storedJSON[type] ?? null
}

export const getLensCrendentials = () => {
  const key = `lens.production.credentials`
  const stored = localStorage.getItem(key)
  if (!stored) return

  const storedJSON = JSON.parse(stored) as LensClientStorageValue
  return storedJSON as LensClientStorageValue
}

export const updateLensCrendentials = (value: string) => {
  const key = `lens.production.credentials`
  const stored = localStorage.getItem(key)
  if (!stored) return

  let storedJSON = JSON.parse(stored) as LensClientStorageValue
  storedJSON = {
    ...storedJSON,
    data: {
      refreshToken: value,
    },
  }

  localStorage.setItem(key, JSON.stringify(storedJSON))
}

export const appendMentionPrefix = (text: string) => {
  const prefix = '@'
  const pattern = new RegExp(prefix, 'g')
  return text.replace(pattern, `${prefix}${HANDLE_PREFIX}`)
}

export const base64ToFile = (base64String: string, filename: string): File => {
  const arr = base64String.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const zoraMetadataUrl = async (uri: string) => {
  try {
    let data

    if (uri.startsWith('ipfs://')) {
      const dataUri = uri.replace('ipfs://', '')
      const fullUrl = `https://${dataUri}.ipfs.nftstorage.link`

      const response = await axios.get(fullUrl)
      data = response.data
    } else {
      const response = await axios.get(uri)
      data = response.data
    }

    if (data.image && data.image.startsWith('ipfs://')) {
      const imageUri = data.image.replace('ipfs://', '')
      data.image = `https://${imageUri}.ipfs.nftstorage.link`
    }

    if (
      data.content &&
      data.content.uri &&
      data.content.uri.startsWith('ipfs://')
    ) {
      const contentUri = data.content.uri.replace('ipfs://', '')
      data.content.uri = `https://${contentUri}.ipfs.nftstorage.link`
    }

    return data
  } catch (error) {
    console.error(`Error fetching metadata: ${error}`)
  }
}

export function encodeMinterArguments(address: string): string {
  if (!ethers.utils.isAddress(address)) {
    throw new Error('Invalid Ethereum address')
  }

  const cleanAddress = address.toLowerCase().replace('0x', '')
  const paddedAddress = cleanAddress.padStart(64, '0')

  return '0x' + paddedAddress
}

export const check1155Ownership = async (
  signer: ethers.Signer,
  tokenContractAddress: `0x${string}`,
  tokenId: number,
  userAddress: string
) => {
  try {
    const contract = new ethers.Contract(
      tokenContractAddress,
      erc1155Abi,
      signer
    )
    const balance = await contract.balanceOf(userAddress, tokenId)
    return Number(balance) > 0
  } catch (error) {
    console.error('Error checking 1155 ownership', error)
    return false
  }
}

export const checkSpecificNetwork = async (
  wallet: ConnectedWallet,
  chainId: number
) => {
  if (wallet.chainId === chainId.toString()) return true
  else {
    try {
      await wallet.switchChain(chainId)
      return true
    } catch (error) {
      console.log('Error switching to specific network', error)
      return false
    }
  }
}

// ** React Imports
import { ReactNode, createContext, useContext } from 'react';

// ** Hooks
import axios from 'axios';

// ** Utils
import { BACKEND_URL } from '../utils/constants/api';
import { GroupBy, Topic, ZoraCurrency } from '../utils/constants/types';
import { FarcasterRank, FeedItem, FollowerHistogram, FollowersHistory, ProfileRank, PodsTrendingType, ProfileItem, TrendingCollectsType, TrendingUserType, RecentFollowers, LinksCount, LowerCaseZoraTrendingType, CastPage, CategoryType, ZoraTrendingType, IFarcasterMatchLens, UniswapTokenData, CategoryByDate, ProtocolEnum, UniswapTokenStatsData } from '@/types/custom';
import { CreatorProfileType, UserCategoriesType, UserCollectType, UserEngagementType, UserProfileBadgeStatsType, UserRankDataType } from '@/types/apps/userProfile';
import { useLens } from './lens';
export type BackendValue = {
  handleJWT: (token: string) => Promise<void>;
  searchUsers: (appIds: string[], interests: string[], activities: number[], groupBy: GroupBy) => Promise<any>;
  fetchLatestPost: (profileId: string) => Promise<any>;
  fetchUsersTrendings: (ratio: string, interest: string, page?: number, limit?: number, protocol?: ProtocolEnum, categoriesIds?: number[]) => Promise<{
    users: TrendingUserType[];
    page: number;
    total: number;
    totalPages: number;
  } | undefined>;
  fetchUserTrendingRank: (profileId: string, ratio: 'likes' | 'follows' | 'collects', protocol: ProtocolEnum) => Promise<UserRankDataType | undefined>;
  fetchContentTrendings: (ratio: string, type: string, take?: number, page?: number, forProfileId?: string) => Promise<TrendingCollectsType[] | undefined>;
  fetchZoraTrendings: (page: number, limit?: number, forProfileId?: string) => Promise<LowerCaseZoraTrendingType[] | undefined>;
  fetchZoraCurrencies: () => Promise<ZoraCurrency[] | undefined>;
  fetchCollectSumForUser: (profileId: string, currency?: string, period?: string | number) => Promise<any>;
  fetchCollectListForUser: (profileId: string, currency?: string, month?: number, year?: number) => Promise<UserCollectType[] | undefined>;
  fetchTrendingPods: (page?: number, take?: number, forProfileId?: string) => Promise<PodsTrendingType[] | undefined>;
  fetchPodDetail: (podId: string, tid: string) => Promise<PodsTrendingType | undefined>;
  fetchTrendingApps: () => Promise<any>;
  fetchTrendingContentType: () => Promise<any>;
  fetchCollectsContentType: () => Promise<any>;
  fetchOrbClubsTrending: (page: number, limit?: number, orderBy?: string) => Promise<any>;
  fetchOrbClubsActivities: () => Promise<any>;
  fetchOrbClubActivities: (clubId: string) => Promise<any>;
  fetchOrbClubMetadata: (clubId: string) => Promise<any>;
  fetchFollowersHistory: (profileId: string) => Promise<any>;
  fetchTopFollowers: (profileId: string) => Promise<{
    profile_id: string;
    follower_id: string;
    follower_count: string;
  }[] | undefined>;
  fetchFollowersDistribution: () => Promise<FollowerHistogram>;
  fetchFarcasterFollowersDistribution: () => Promise<FollowerHistogram[]>;
  fetchFarcasterUserFollowing: (fid: string) => Promise<number[]>;
  fetchFarcasterUserRank: (fid: string) => Promise<FarcasterRank>;
  fetchFarcasterProfileRank: (fid: string) => Promise<ProfileRank>;
  fetchFarcasterLatestsFollowers: (fid: string) => Promise<RecentFollowers>;
  fetchFarcasterCasts: (fid: string, limit: number, skip: number) => Promise<CastPage>;
  fetchFarcasterUserEngagement: (fid: string) => Promise<UserEngagementType | undefined>;
  fetchFarcasterTopFollowers: (fid: string) => Promise<ProfileItem[] | undefined>;
  fetchActiveUsers: () => Promise<any>;
  fetchTrendingCollectApps: () => Promise<any>;
  fetchTrendingAppsPerContentType: () => Promise<any>;
  fetchFollowingsFarcasterMatchLens: (handle: string, page?: number, limit?: number) => Promise<IFarcasterMatchLens>;
  getUserFarcaster: (fid: string) => Promise<ProfileItem>;
  getProfilesFarcasterByHandle: (handle: string) => Promise<ProfileItem[]>;
  getUsersFarcaster: (fids: number[]) => Promise<ProfileItem[]>;
  fetchUserFarcasterFollowers: (profileId: string) => Promise<number[]>;
  fetchUserFarcasterFollowersHistory: (profileId: string) => Promise<FollowersHistory[]>;
  fetchUsersFarcasterTotalFollowers: (fids: number[]) => Promise<LinksCount[]>;
  getFeedPage: (forProfileId?: string, cursor?: string, limit?: number) => Promise<{
    items: Array<FeedItem>;
    nextCursor?: string;
  } | undefined>;
  fetchUserLatestsFollowers: (profileId: string) => Promise<any>;
  fetchUserEngagement: (profileId: string) => Promise<UserEngagementType | undefined>;
  fetchUserRank: (profileId: string) => Promise<UserProfileBadgeStatsType | undefined>;
  fetchTopCreators: (days: number, page?: number, limit?: number) => Promise<CreatorProfileType[] | undefined>;
  fetchCategoriesList: () => Promise<CategoryType[] | undefined>;
  fetchUserCategories: (profileId: string) => Promise<UserCategoriesType | undefined>;
  storeUserCategories: (profileId: string, categories: number[]) => Promise<(UserCategoriesType & {
    success: boolean;
  }) | undefined>;
  removeUserCategories: (profileId: string, categories: number[]) => Promise<(UserCategoriesType & {
    success: boolean;
  }) | undefined>;
  fetchLensCollectsForProfile: (profileId: string, page: number, limit?: number) => Promise<TrendingCollectsType[] | undefined>;
  fetchZoraMintsForProfile: (page: number, limit?: number, forProfileId?: string) => Promise<LowerCaseZoraTrendingType[] | undefined>;
  fetchZoraDetail: (tokenAddress: string, tid: string) => Promise<ZoraTrendingType | undefined>;
  fetchPodsMintsForProfile: (forProfileId: string, page: number, limit?: number) => Promise<any>;
  getShowOnFeed: (profileId: string) => Promise<{
    value: boolean;
    profileId: string;
  } | undefined>;
  setShowOnFeed: (profileId: string) => Promise<{
    success: boolean;
    profileId: string;
  } | undefined>;
  deleteShowOnFeed: (profileId: string) => Promise<{
    success: boolean;
  } | undefined>;
  getTopics: (protocol: ProtocolEnum) => Promise<Topic[] | undefined>;
  getCategories: (date: string) => Promise<CategoryByDate[] | undefined>;
  getTopicsRelations: (topicId: string, date: string) => Promise<any>;
  fetchTokensMentions: (page: number, limit: number) => Promise<any>;
  fetchUniswapData: (profileId?: string, page?: number, limit?: number) => Promise<UniswapTokenData[] | undefined>;
  fetchUniswapTokensData: (protocol: ProtocolEnum, profileId?: string, page?: number, limit?: number) => Promise<UniswapTokenStatsData[] | undefined>;
  getRelatedTokensPosts: (tokenId: string) => Promise<any>;
  getRelatedCategoryPosts: (date: string, category: string) => Promise<any>;
  fetchTopEngagers: (topicId: string, profileId?: string) => Promise<{
    followedEngagers: {
      profileId: string;
    }[];
    topEngagers: {
      profileId: string;
      followersCount: string;
    }[];
  } | undefined>;
  fetchTopicMentions: (topicId: string) => Promise<any>;
  fetchTopicsTags: () => Promise<{
    id: string;
    name: string;
  }[] | undefined>;
};
const BackendContext = createContext<BackendValue>({} as BackendValue);
export const loginAuth = async (address: string, signature: string) => {
  try {
    const res = await axios.post(`${BACKEND_URL}/auth/login`, {
      address,
      signature
    });
    const token = res.data.access_token as string;
    return token;
  } catch (error) {
    console.log('Error fetching auth sign in:', error);
    return null;
  }
};
export function BackendProvider({
  children
}: {
  children: ReactNode;
}) {
  const {
    jwt
  } = useLens();
  const fetchLatestPost = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/post/latest/${profileId}`);
      return res.data[0];
    } catch (error) {
      console.log('Error fetching latest post:', error);
    }
  };
  const fetchUsersTrendings = async (ratio: string, interest: string, page?: number, limit = 50, protocol: ProtocolEnum = ProtocolEnum.LENS, categories?: number[]) => {
    try {
      const res = await axios.post(`${BACKEND_URL}/userTrending/find`, {
        ratio,
        interest,
        page: page ? page : 1,
        limit,
        protocol,
        categories
      });
      return res.data as {
        users: TrendingUserType[];
        page: number;
        total: number;
        totalPages: number;
      };
    } catch (error) {
      console.log('Error fetching trending users:', error);
    }
  };
  const fetchUserTrendingRank = async (profileId: string, ratio: 'likes' | 'follows' | 'collects', protocol: ProtocolEnum = ProtocolEnum.LENS) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userTrending/user/rank/${profileId}?ratio=${ratio}&protocol=${protocol}`);
      return res.data as UserRankDataType;
    } catch (error) {
      console.log('Error fetching user trending rank:', error);
    }
  };
  const fetchContentTrendings = async (ratio: string, type: string, take?: number, page?: number, forProfileId?: string) => {
    try {
      const res = await axios.post(`${BACKEND_URL}/contentTrending/find`, {
        ratio,
        type,
        take,
        page: page ? page : 1,
        forProfileId
      });
      return res.data as TrendingCollectsType[];
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchZoraTrendings = async (page: number, limit: number = 50, forProfileId?: string) => {
    try {
      const query = new URLSearchParams();
      query.append('page', page.toString());
      query.append('limit', limit.toString());
      if (forProfileId) query.append('forProfileId', forProfileId);
      const endpoint = `${BACKEND_URL}/zora/trending?${query.toString()}`;
      const res = await axios.get(endpoint);
      return res.data as LowerCaseZoraTrendingType[];
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchZoraCurrencies = async () => {
    try {
      const endpoint = `${BACKEND_URL}/lens-api/currencies`;
      const res = await axios.get(endpoint);
      return res.data;
    } catch (error) {
      console.log('Error fetching zora currencies:', error);
    }
  };
  const fetchCollectSumForUser = async (profileId: string, currency?: string, period?: string | number) => {
    try {
      const baseEndpoint = `${BACKEND_URL}/user/profile/collect/${profileId}`;
      const query = new URLSearchParams();
      if (currency) query.append('currency', currency);
      if (period === 'All') {
        query.append('period', period.toUpperCase());
      } else if (period) {
        query.append('period', period as string);
      }
      const res = await axios.get(`${baseEndpoint}?${query.toString()}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching user collect currencies sum:', error);
    }
  };
  const fetchCollectListForUser = async (profileId: string, currency?: string, month?: number, year?: number) => {
    try {
      const baseEndpoint = `${BACKEND_URL}/user/profile/collect/list/${profileId}`;
      const query = new URLSearchParams();
      if (currency) query.append('currency', currency);
      if (month !== undefined) query.append('month', month.toString());
      if (year !== undefined) query.append('year', year.toString());
      const res = await axios.get(`${baseEndpoint}?${query.toString()}`);
      return res.data as UserCollectType[];
    } catch (error) {
      console.log('Error fetching user collect currencies list:', error);
    }
  };
  const fetchTrendingPods = async (page?: number, take: number = 20, forProfileId?: string) => {
    try {
      const query = new URLSearchParams();
      if (take) query.append('take', take.toString());
      if (page) query.append('page', page.toString());
      if (forProfileId) query.append('forProfileId', forProfileId);
      const endpoint = `${BACKEND_URL}/pods/trending?${query.toString()}`;
      const res = await axios.get(endpoint);
      return res.data as PodsTrendingType[];
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchTrendingApps = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/appTrending/trending`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchTrendingAppsPerContentType = async () => {
    try {
      const contentTypes = {
        Text: ['TEXT_ONLY'],
        Audio: ['AUDIO'],
        Video: ['VIDEO', 'SHORT_VIDEO', 'LIVESTREAM'],
        Image: ['IMAGE'],
        Article: ['ARTICLE']
      };
      const result = {
        Text: [],
        Audio: [],
        Video: [],
        Image: [],
        Article: []
      };
      const res = await axios.get(`${BACKEND_URL}/appTrending/contentTypeApps`, {});
      res.data.forEach(item => {
        for (const [key, types] of Object.entries(contentTypes)) {
          if (types.includes(item.type)) {
            const existingApp = result[key].find(app => app.identifier === item.identifier);
            if (existingApp) {
              existingApp.count += item.ratioCount;
            } else {
              result[key].push({
                identifier: item.identifier,
                count: item.ratioCount
              });
            }
          }
        }
      });
      for (const key in result) {
        result[key].sort((a, b) => b.count - a.count);
        result[key] = result[key].slice(0, 3);
      }
      return result;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const getUserFarcaster = async (fid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/profile/${fid}`);
      return res.data;
    } catch (error) {
      console.error('Error fetching user farcaster:', error);
    }
  };
  const getProfilesFarcasterByHandle = async (handle: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/profiles/${handle}`);
      return res.data;
    } catch (error) {
      console.error('Error fetching profiles farcaster by handle:', error);
    }
  };
  const fetchUserFarcasterFollowers = async (fid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/followers/${fid}`);
      return res.data;
    } catch (error) {
      console.error('Error fetching user farcaster followers:', error);
    }
  };
  const fetchUserFarcasterFollowersHistory = async (fid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/followers-history/${fid}`);
      return res.data;
    } catch (error) {
      console.error('Error fetching user farcaster followers history:', error);
    }
  };
  const fetchFarcasterFollowersDistribution = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/followers/histogram`);
      return res.data;
    } catch (error) {
      console.log('Error fetching top followers:', error);
    }
  };
  const fetchFarcasterUserFollowing = async (fid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/following/${fid}`);
      return res.data;
    } catch (error) {
      console.error('Error fetching user farcaster following:', error);
    }
  };
  const fetchFarcasterUserRank = async (fid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/rank/${fid}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching user rank:', error);
    }
  };
  const fetchFarcasterProfileRank = async (fid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/profile/rank/${fid}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching user rank:', error);
    }
  };
  const fetchFarcasterLatestsFollowers = async (fid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/recentFollowers/${fid}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching user latest followers:', error);
    }
  };
  const getUsersFarcaster = async (fids: number[]) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/profiles`, {
        params: {
          fids
        }
      });
      return res.data;
    } catch (error) {
      console.error('Error fetching users farcaster:', error);
    }
  };
  const fetchUsersFarcasterTotalFollowers = async (fids: number[]) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/totalFollowers`, {
        params: {
          fids
        }
      });
      return res.data;
    } catch (error) {
      console.error('Error fetching users farcaster total followers:', error);
    }
  };
  const fetchFarcasterCasts = async (fid: string, limit: number, skip: number) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/casts/${fid}`, {
        params: {
          limit,
          skip
        }
      });
      return res.data;
    } catch (error) {
      console.error('Error fetching user farcaster casts:', error);
    }
  };
  const fetchFarcasterUserEngagement = async (fid: string): Promise<UserEngagementType | undefined> => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/engagement/${fid}`);
      return res.data as UserEngagementType;
    } catch (error) {
      console.log('Error fetching user engagement:', error);
    }
  };
  const fetchFarcasterTopFollowers = async (fid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/user/followers/top/${fid}`);
      return res.data as ProfileItem[];
    } catch (error) {
      console.log('Error fetching top followers:', error);
    }
  };
  const fetchTrendingCollectApps = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/appTrending/collectsApps`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchActiveUsers = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/appTrending/activeUsers`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchTrendingContentType = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/contentTypeTrending/trending`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchCollectsContentType = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/contentTypeTrending/collects`, {});
      return res.data;
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchOrbClubsTrending = async (page: number, limit: number = 50, orderBy?: string) => {
    try {
      const query = new URLSearchParams();
      query.append('page', page.toString());
      query.append('limit', limit.toString());
      if (orderBy) {
        query.append('orderBy', orderBy);
      }
      const base = `${BACKEND_URL}/orbclubstrending/find`;
      const queryStringify = query.toString();
      const endpoint = queryStringify ? `${base}?${queryStringify}` : base;
      const res = await axios.post(endpoint);
      return res.data;
    } catch (error) {
      console.log('Error fetching orb clubs trendings:', error);
    }
  };
  const fetchOrbClubMetadata = async (clubId: string) => {
    try {
      const query = new URLSearchParams();
      query.append('clubId', clubId.toString());
      const base = `${BACKEND_URL}/orbclubstrending/club-data`;
      const endpoint = `${base}?${query}`;
      const res = await axios.post(endpoint);
      return res.data;
    } catch (error) {
      console.log('Error fetching orb club metadata:', error);
    }
  };
  const fetchOrbClubsActivities = async () => {
    try {
      const base = `${BACKEND_URL}/orbclubstrending/clubs-activity`;
      const res = await axios.get(base);
      return res.data;
    } catch (error) {
      console.log('Error fetching orb clubs activities:', error);
    }
  };
  const fetchOrbClubActivities = async (clubId: string) => {
    try {
      const query = new URLSearchParams();
      query.append('clubId', clubId.toString());
      const base = `${BACKEND_URL}/orbclubstrending/club-activity`;
      const endpoint = `${base}?${query}`;
      const res = await axios.post(endpoint);
      return res.data;
    } catch (error) {
      console.log('Error fetching orb club activities:', error);
    }
  };
  const fetchTopFollowers = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userFollowers/topFollowers/${profileId}`);
      return res.data as {
        profile_id: string;
        follower_id: string;
        follower_count: string;
      }[];
    } catch (error) {
      console.log('Error fetching top followers:', error);
    }
  };
  const fetchFollowersHistory = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userFollowers/followersHistory/${profileId}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching followers history:', error);
    }
  };
  const fetchFollowersDistribution = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userFollowers/histogram/`);
      return res.data;
    } catch (error) {
      console.log('Error fetching top followers:', error);
    }
  };
  const getFeedPage = async (forProfileId?: string, cursor?: string, limit: number = 10) => {
    try {
      const params = new URLSearchParams();
      if (cursor) params.append('cursor', cursor);
      if (limit !== 50) params.append('limit', limit.toString());
      if (forProfileId) params.append('forProfileId', forProfileId);
      const url = `${BACKEND_URL}/feed${params.toString() ? `?${params.toString()}` : ''}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log('Error fetching feed page:', error);
    }
  };
  const fetchUserLatestsFollowers = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userFollowers/recentFollowers/${profileId}`);
      return res.data.followers;
    } catch (error) {
      console.log('Error fetching user latest followers:', error);
    }
  };
  const fetchUserEngagement = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/user/profile/engagement/${profileId}`);
      return res.data as UserEngagementType;
    } catch (error) {
      console.log('Error fetching user engagement:', error);
    }
  };
  const fetchUserRank = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/user/profile/rank/${profileId}`);
      return res.data as UserProfileBadgeStatsType;
    } catch (error) {
      console.log('Error fetching user rank:', error);
    }
  };
  const fetchCategoriesList = async () => {
    try {
      const endpoint = `${BACKEND_URL}/category/list`;
      const res = await axios.get(endpoint);
      return res.data as CategoryType[];
    } catch (error) {
      console.log('Error fetching categories list:', error);
    }
  };
  const fetchTopCreators = async (days: number, page?: number, limit?: number) => {
    try {
      const query = new URLSearchParams();
      query.append('days', days.toString());
      if (page) query.append('page', page.toString());
      if (limit) query.append('limit', limit.toString());
      const base = `${BACKEND_URL}/user/profile/creator/top`;
      const endpoint = `${base}?${query}`;
      const res = await axios.get(endpoint);
      return res.data as CreatorProfileType[];
    } catch (error) {
      console.log('Error fetching user rank:', error);
    }
  };
  const fetchUserCategories = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/userCategories/${profileId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return res.data as UserCategoriesType;
    } catch (error) {
      console.log('Error fetching user categories:', error);
    }
  };
  const storeUserCategories = async (profileId: string, categories: number[]) => {
    try {
      const res = await axios.post(`${BACKEND_URL}/userCategories/${profileId}`, {
        categories
      }, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return res.data as UserCategoriesType & {
        success: boolean;
      };
    } catch (error) {
      console.log('Error storing user categories:', error);
    }
  };
  const removeUserCategories = async (profileId: string, categories: number[]) => {
    try {
      console.log('categories', categories);
      const res = await axios.delete(`${BACKEND_URL}/userCategories/${profileId}`, {
        data: {
          categories
        },
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return res.data as UserCategoriesType & {
        success: boolean;
      };
    } catch (error) {
      console.log('Error removing user categories:', error);
    }
  };
  const fetchLensCollectsForProfile = async (profileId: string, page: number, limit: number = 50) => {
    try {
      const query = new URLSearchParams();
      query.append('page', page.toString());
      query.append('take', limit.toString());
      const endpoint = `${BACKEND_URL}/contentTrending/mints/${profileId}?${query.toString()}`;
      const res = await axios.get(endpoint);
      return res.data as TrendingCollectsType[];
    } catch (error) {
      console.log('Error fetching lens collects:', error);
    }
  };
  const fetchZoraMintsForProfile = async (page: number, limit: number = 50, forProfileId?: string) => {
    try {
      const query = new URLSearchParams();
      query.append('page', page.toString());
      query.append('take', limit.toString());
      const endpoint = `${BACKEND_URL}/zora/trending/${forProfileId}?${query.toString()}`;
      const res = await axios.get(endpoint);
      return res.data as LowerCaseZoraTrendingType[];
    } catch (error) {
      console.log('Error fetching content trendings:', error);
    }
  };
  const fetchZoraDetail = async (tokenAddress: string, tid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/zora/detail/${tokenAddress}?tid=${tid}`);
      return res.data as ZoraTrendingType;
    } catch (error) {
      console.log('Error fetching zora detail:', error);
    }
  };
  const fetchPodsMintsForProfile = async (forProfileId: string, page: number, limit: number = 50) => {
    try {
      const query = new URLSearchParams();
      query.append('page', page.toString());
      query.append('take', limit.toString());
      const res = await axios.get(`${BACKEND_URL}/pods/trending/${forProfileId}?${query.toString()}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching pods mints:', error);
    }
  };
  const fetchPodDetail = async (podId: string, tid: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/pods/detail/${podId}?tid=${tid}`);
      return res.data as PodsTrendingType;
    } catch (error) {
      console.log('Error fetching pod detail:', error);
    }
  };
  const getShowOnFeed = async (profileId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/show-feed/${profileId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return res.data as {
        value: boolean;
        profileId: string;
      };
    } catch (error) {
      console.log('Error fetching show on feed:', error);
    }
  };
  const setShowOnFeed = async (profileId: string) => {
    try {
      const res = await axios.post(`${BACKEND_URL}/show-feed`, {
        profileId
      }, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return res.data as {
        success: boolean;
        profileId: string;
      };
    } catch (error) {
      console.log('Error setting show on feed:', error);
    }
  };
  const fetchFollowingsFarcasterMatchLens = async (handle: string, page: number, limit: number) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/farcaster/match`, {
        params: {
          handle,
          page,
          limit
        }
      });
      return res.data;
    } catch (error) {
      console.log('Error fetching user farcaster match lens:', error);
    }
  };
  const deleteShowOnFeed = async (profileId: string) => {
    try {
      const res = await axios.delete(`${BACKEND_URL}/show-feed/${profileId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return res.data as {
        success: boolean;
      };
    } catch (error) {
      console.log('Error deleting show on feed:', error);
    }
  };
  const getTopics = async (protocol: ProtocolEnum) => {
    try {
      const query = new URLSearchParams();
      query.append('protocol', protocol.toString());
      const res = await axios.get(`${BACKEND_URL}/topics?${query.toString()}`);
      return res.data as Topic[];
    } catch (error) {
      console.log('Error fetching topics:', error);
    }
  };
  const getCategories = async (date: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/categories?date=${date}`);
      return res.data as CategoryByDate[];
    } catch (error) {
      console.log('Error fetching categories by date: ', error);
    }
  };
  const getTopicsRelations = async (topicId: string, date: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/topics/relations?id=${topicId}&date=${date}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching topics relations:', error);
    }
  };
  const fetchTokensMentions = async (page: number, limit: number) => {
    try {
      const query = new URLSearchParams();
      query.append('page', page.toString());
      query.append('limit', limit.toString());
      const res = await axios.get(`${BACKEND_URL}/tokens/mentions?${query.toString()}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching tokens mentions:', error);
    }
  };
  const fetchUniswapData = async (profileId?: string, page?: number, limit?: number) => {
    try {
      const query = new URLSearchParams();
      if (profileId) query.append('forProfileId', profileId.toString());
      if (page) query.append('page', page.toString());
      if (limit) query.append('take', limit.toString());
      const res = await axios.get(`${BACKEND_URL}/uniswap/trending/tokens?${query.toString()}`);
      return res.data as UniswapTokenData[];
    } catch (error) {
      console.log('Error fetching uniswap tokens data:', error);
    }
  };
  const fetchUniswapTokensData = async (protocol: ProtocolEnum, profileId?: string, page?: number, limit?: number) => {
    try {
      const query = new URLSearchParams();
      query.append('protocol', protocol);
      if (profileId) query.append('forProfileId', profileId.toString());
      if (page) query.append('page', page.toString());
      if (limit) query.append('take', limit.toString());
      const res = await axios.get(`${BACKEND_URL}/uniswap/trending/tokens/stats?${query.toString()}`);
      return res.data as UniswapTokenStatsData[];
    } catch (error) {
      console.log('Error fetching uniswap tokens stats data: ', error);
    }
  };
  const getRelatedTokensPosts = async (tokenId: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/tokens/mentions/publications/${tokenId}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching related token posts:', error);
    }
  };
  const getRelatedCategoryPosts = async (date: string, category: string) => {
    try {
      const res = await axios.get(`${BACKEND_URL}/categories/publications?date=${date}&category=${category}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching related category posts:', error);
    }
  };
  const fetchTopEngagers = async (topicId: string, profileId?: string) => {
    try {
      const query = new URLSearchParams();
      if (profileId) query.append('forProfileId', profileId.toString());
      if (topicId) query.append('topicId', topicId.toString());
      const res = await axios.get(`${BACKEND_URL}/topics/engagers?${query.toString()}`);
      return res.data as {
        followedEngagers: {
          profileId: string;
        }[];
        topEngagers: {
          profileId: string;
          followersCount: string;
        }[];
      };
    } catch (error) {
      console.log('Error fetching top engagers:', error);
    }
  };
  const fetchTopicMentions = async (topicId: string) => {
    try {
      const query = new URLSearchParams();
      if (topicId) query.append('topicId', topicId.toString());
      const res = await axios.get(`${BACKEND_URL}/topics/mentions?${query.toString()}`);
      return res.data;
    } catch (error) {
      console.log('Error fetching topic mentions:', error);
    }
  };
  const fetchTopicsTags = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/topics/tags`);
      return res.data as {
        id: string;
        name: string;
      }[];
    } catch (error) {
      console.log('Error fetching topics tags: ', error);
    }
  };
  const value: BackendValue = {
    fetchLatestPost,
    fetchUsersTrendings,
    fetchUserTrendingRank,
    fetchContentTrendings,
    fetchZoraTrendings,
    fetchZoraCurrencies,
    fetchCollectSumForUser,
    fetchCollectListForUser,
    fetchTrendingPods,
    fetchTrendingApps,
    fetchTrendingContentType,
    fetchCollectsContentType,
    fetchOrbClubsTrending,
    fetchOrbClubsActivities,
    fetchOrbClubActivities,
    fetchOrbClubMetadata,
    fetchFollowersHistory,
    fetchTopFollowers,
    fetchFollowersDistribution,
    fetchActiveUsers,
    fetchTrendingCollectApps,
    fetchTrendingAppsPerContentType,
    getUserFarcaster,
    getUsersFarcaster,
    fetchFarcasterCasts,
    fetchUserFarcasterFollowers,
    fetchFarcasterFollowersDistribution,
    fetchUserFarcasterFollowersHistory,
    fetchUsersFarcasterTotalFollowers,
    fetchFarcasterUserFollowing,
    fetchFarcasterUserRank,
    fetchFarcasterProfileRank,
    fetchFarcasterLatestsFollowers,
    fetchFarcasterUserEngagement,
    fetchFarcasterTopFollowers,
    fetchFollowingsFarcasterMatchLens,
    getFeedPage,
    getProfilesFarcasterByHandle,
    fetchUserLatestsFollowers,
    fetchUserEngagement,
    fetchUserRank,
    fetchTopCreators,
    fetchCategoriesList,
    fetchUserCategories,
    storeUserCategories,
    removeUserCategories,
    fetchLensCollectsForProfile,
    fetchZoraMintsForProfile,
    fetchZoraDetail,
    fetchPodsMintsForProfile,
    fetchPodDetail,
    getShowOnFeed,
    setShowOnFeed,
    deleteShowOnFeed,
    getTopics,
    getCategories,
    getTopicsRelations,
    fetchTokensMentions,
    fetchUniswapData,
    fetchUniswapTokensData,
    getRelatedTokensPosts,
    getRelatedCategoryPosts,
    fetchTopEngagers,
    fetchTopicMentions,
    fetchTopicsTags
  };
  return <BackendContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="BackendProvider" data-sentry-source-file="backend.tsx">{children}</BackendContext.Provider>;
}
export function useBackend() {
  return useContext(BackendContext);
}
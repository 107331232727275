/* eslint @typescript-eslint/no-unused-vars: 0 */
// ** React, Mui & Next
import { useTheme, useMediaQuery } from '@mui/material';

// ** Components
import PageContainer from '../container/PageContainer';
import MobileOverview from './mobile';
import DesktopOverview from './desktop';

/////////////////////////////////////////////
export default function OverviewComponent() {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('md'));
  return <PageContainer data-sentry-element="PageContainer" data-sentry-component="OverviewComponent" data-sentry-source-file="OverviewComponent.tsx">
      {isXsScreen ? <MobileOverview /> : <DesktopOverview />}
    </PageContainer>;
}
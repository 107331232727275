import { ExtendedButtonColorProp } from '@/utils/constants/types';
import { Chip, ChipProps, useTheme } from '@mui/material';
type TopicTagChipProps = {
  label: ChipProps['label'];
  color: ExtendedButtonColorProp;
  variant?: ChipProps['variant'];
};
export const TopicTagChip = ({
  color,
  label,
  variant
}: TopicTagChipProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  if (color === 'pink') {
    return <Chip label={label} size="small" variant={variant} sx={{
      color: 'white',
      bgcolor: '#FF1493'
    }} />;
  }
  if (color === 'dark-red') {
    return <Chip label={label} size="small" variant={variant} sx={{
      color: 'white',
      bgcolor: '#BF2A02'
    }} />;
  }
  if (color === 'white') {
    return <Chip label={label} size="small" variant={variant} sx={{
      color: isDarkMode ? theme.palette.background.paper : 'white',
      bgcolor: isDarkMode ? '#EAEFF4' : 'black'
    }} />;
  }
  if (color === 'grey') {
    return <Chip label={label} size="small" variant={variant} sx={{
      color: 'white',
      bgcolor: isDarkMode ? theme.palette.grey[200] : theme.palette.grey[400]
    }} />;
  }
  return <Chip label={label} size="small" color={color as any} variant={variant} data-sentry-element="Chip" data-sentry-component="TopicTagChip" data-sentry-source-file="TopicTagChip.tsx" />;
};
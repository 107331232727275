import { gql } from '@apollo/client'

export const VERIFY_JWT = gql`
  query Verify($token: Jwt) {
    verify(request: { accessToken: $token })
  }
`

export const REFRESH_LENS_ACCESS_TOKEN = gql`
  mutation Refresh($refreshToken: Jwt!) {
    refresh(request: { refreshToken: $refreshToken }) {
      accessToken
      refreshToken
      identityToken
    }
  }
`

export const EXPLORE_PUBLICATIONS_QUERY = gql`
  query ExplorePublications($request: ExplorePublicationRequest!) {
    explorePublications(request: $request) {
      items {
        ... on Post {
          id
          by {
            id
            handle {
              localName
            }
            metadata {
              displayName
              bio
              picture {
                ... on ImageSet {
                  optimized {
                    uri
                  }
                }
              }
            }
            stats {
              lensClassifierScore
              followers
            }
          }
          createdAt
          stats {
            bookmarks
            comments
            mirrors
            quotes
            reactions
            countOpenActions
          }
        }
      }
      pageInfo {
        next
        prev
      }
    }
  }
`

export const PROPOSAL_PUBLICATIONS_QUERY = gql`
  query ProposalPublications(
    $proposalsRequest: PublicationsRequest!
    $statsRequest: PublicationStatsInput
    $upvotesRequest: PublicationStatsReactionArgs
    $downvotesRequest: PublicationStatsReactionArgs
    $hasUpvotedRequest: PublicationOperationsReactionArgs
    $hasDownvotedRequest: PublicationOperationsReactionArgs
  ) {
    publications(request: $proposalsRequest) {
      items {
        ... on Post {
          id
          by {
            id
            handle {
              localName
            }
            metadata {
              displayName
              bio
              picture {
                ... on ImageSet {
                  optimized {
                    uri
                  }
                }
              }
            }
          }
          createdAt
          stats(request: $statsRequest) {
            upvotes: reactions(request: $upvotesRequest)
            downvotes: reactions(request: $downvotesRequest)
          }
          operations {
            hasUpvoted: hasReacted(request: $hasUpvotedRequest)
            hasDownvoted: hasReacted(request: $hasDownvotedRequest)
          }
          metadata {
            ... on TextOnlyMetadataV3 {
              content
            }
          }
        }
      }
    }
  }
`
